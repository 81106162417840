import React from 'react'
import { version } from '../../../../package.json'

interface VersionProps {
  isMobile?: boolean
}

const Version = ({ isMobile }: VersionProps): JSX.Element => {
  return (
    <div
      className={`${
        isMobile
          ? 'rf-px-5 rf-pt-6 rf-text-xsm'
          : 'rf-absolute rf-left-8 rf-bottom-2 rf-text-sm'
      } rf-text-[#A4A7AF] rf-text-sm`}
    >
      {`v ${version}`}
    </div>
  )
}

export default Version
