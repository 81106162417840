/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { animated, useSpring, easings } from '@react-spring/web'

import { useSpeechStore } from '../Contexts/speechStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import { setUserSwiping } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'

import useRefDimensions from '../../hooks/useRefDimensions'

import type { WithChildren } from '../../models/utils'
import { DeviceType, Mode } from '../../models/enums'

import { Wheeler, Swiper } from '../Utils'

const springEnterDuration = 1000

type CarouselProps = WithChildren<{
  ref?: any
  direction?: 'horizontal' | 'vertical'
  history?: boolean
  draft?: boolean
  grid?: boolean
  gridTitle?: string
}>

// Cards' width coefficients depending on the mode and display type
const widthCoefficients = {
  textAndVocalMobile: 0.9,
  textAndVocalMobileGrid: 0.45,
  vocalDesktop: 0.6,
  vocalBorne: 0.45
}

const defaultTransitionDuration = '300ms'
const velocityBreakpoint = 0.5

const Carousel = React.forwardRef<HTMLDivElement, CarouselProps>(
  ({ direction, history, draft, grid, gridTitle, children }, ref) => {
    const mode = useRetorikStore((state) => state.mode)
    const forceMobileView = useRetorikStore(
      (state) => state.configuration.forceMobileView
    )
    const currentDeviceType = useViewStore((state) => state.currentDeviceType)
    const isMobile = useViewStore((state) => state.isMobile)
    const showHomeAttachments = useViewStore(
      (state) => state.showHomeAttachments
    )
    const currentOrLastPlayedActivity = useSpeechStore(
      (state) => state.currentOrLastPlayedActivity
    )

    const nbCards = useMemo<number>(() => {
      return React.Children.count(children)
    }, [children])

    const gap = useMemo<number>(() => {
      return isMobile ? 0.3 : 1
    }, [currentDeviceType])

    const coefficient = useMemo<number>(() => {
      return mode === Mode.vocal
        ? currentDeviceType === DeviceType.landscape ||
          currentDeviceType === DeviceType.widgetLandscape
          ? history
            ? widthCoefficients.textAndVocalMobile
            : widthCoefficients.vocalDesktop
          : currentDeviceType === DeviceType.borne ||
            currentDeviceType === DeviceType.widgetBorne
          ? history
            ? widthCoefficients.vocalDesktop
            : widthCoefficients.vocalBorne
          : grid
          ? widthCoefficients.textAndVocalMobileGrid
          : widthCoefficients.textAndVocalMobile
        : grid
        ? widthCoefficients.textAndVocalMobileGrid
        : widthCoefficients.textAndVocalMobile
    }, [mode, currentDeviceType, grid])

    const [cardWidth, setCardWidth] = useState<number>(
      currentDeviceType === DeviceType.widget || forceMobileView
        ? 400 * coefficient
        : window.innerWidth * coefficient
    )
    const [cardHeight, setCardHeight] = useState<number>()
    const [currentCard, setCurrentCard] = useState<number>(0)
    const [currentPadding, setCurrentPadding] = useState<number>()
    const [translation, setTranslation] = useState<string>('')
    const [transitionDuration, setTransitionDuration] = useState<string>('1ms')
    const [gridTemplateColumn, setGridTemplateColumn] = useState<string>(
      direction !== 'vertical' ? `repeat(${nbCards}, ${cardWidth}px)` : ''
    )
    const [gridTemplateRow, setGridTemplateRow] = useState<string>(
      direction === 'vertical'
        ? `repeat(${nbCards}, ${(cardWidth * 10) / 16}px)`
        : ''
    )
    const [enableTranslation, setEnableTranslation] = useState<boolean>(true)
    const [wheeling, setWheeling] = useState<number>(0)
    const divRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
    const timerRef: React.MutableRefObject<any> = useRef(null)

    const dimensions = useRefDimensions(divRef)

    const [spring, api] = useSpring(() => ({
      from: {
        transform: 'translateX(0%)'
      }
    }))

    useEffect(() => {
      !draft &&
        api.start({
          from: {
            transform: 'translateX(100%)'
          },
          to: {
            transform: 'translateX(0%)'
          },
          config: {
            duration: springEnterDuration,
            easing: easings.easeInBack
          }
        })
    }, [draft])

    /**
     * On cards container reference, mode / currentDeviceType states change :
     *  - calculate the cards' width depending on the coefficient
     *  - calculate the cards' height depending on the width (use a 16/10 format). Only used in vertical disposition
     *  - set the currentPadding state to have centered cards in horizontal disposition
     *  - set cardWidth, cardHeight and gridTemplateRow / gridTemplateColumn (depending on direction) states
     * On component unmount :
     *  - clear timeout
     */
    useEffect(() => {
      if (dimensions) {
        let newCardWidth = Math.floor(dimensions.width * coefficient)
        // Get the number of pixels contained in 1 rem multiplicated by the gap, used with grid elements
        const gapInPx =
          gap * parseFloat(getComputedStyle(document.documentElement).fontSize)

        if (forceMobileView && newCardWidth > 400) {
          newCardWidth = 400
          setCurrentPadding(
            grid
              ? (dimensions.width - newCardWidth * 2 - gapInPx) / 2
              : (dimensions.width - newCardWidth - 5 * (nbCards - 1)) / 2
          )
          if (nbCards * newCardWidth < dimensions.width) {
            setEnableTranslation(false)
          }
        } else {
          setCurrentPadding(
            grid
              ? (dimensions.width - newCardWidth * 2 - gapInPx) / 2
              : ((1 - coefficient) * dimensions.width) / 2
          )
        }
        const newCardHeight = Math.ceil((newCardWidth * 10) / 16)
        // Space needed to center to cards
        // Cards' width (all dispositions) and height (only used in vertical disposition)
        setCardWidth(newCardWidth)
        setCardHeight(newCardHeight)
        direction === 'vertical'
          ? setGridTemplateRow(`repeat(${nbCards}, ${newCardHeight}px)`)
          : setGridTemplateColumn(`repeat(${nbCards}, ${newCardWidth}px)`)

        setCurrentCard(0)
      }

      return (): void => timerRef && clearTimeout(timerRef.current)
    }, [
      dimensions,
      coefficient,
      nbCards,
      currentDeviceType,
      currentOrLastPlayedActivity?.id
    ])

    /**
     * On currentCard, cardWidth, cardHeight, currentPadding states change :
     *  - set translation state depending on the direction
     */
    useEffect(() => {
      if (
        currentCard !== undefined &&
        cardWidth !== undefined &&
        cardHeight !== undefined &&
        currentPadding !== undefined
      ) {
        direction === 'vertical'
          ? setTranslation(
              `translateY(calc(-${currentCard * cardHeight}px - ${
                currentCard * gap
              }rem))`
            )
          : setTranslation(
              `translateX(calc(-${currentCard * cardWidth}px - ${
                currentCard * gap
              }rem + ${currentPadding}px))`
            )
      }
    }, [currentCard, cardWidth, cardHeight, currentPadding])

    /**
     * On wheeling state change :
     *  - call either onSwipeRight or onSwipeLeft (with high values to enter the right part of the if condition), depending on the wheeling value (positive or negative)
     */
    useEffect(() => {
      wheeling &&
        (wheeling > 0 ? onSwipeLeft(1000, 1000) : onSwipeRight(1000, 1000))
    }, [wheeling])

    useEffect(() => {
      if (showHomeAttachments) {
        setCurrentCard(0)
      }
    }, [showHomeAttachments])

    /**
     * On call :
     *  - set translation to its initial state
     */
    const resetPosition = (): void => {
      cardWidth &&
        setTranslation(
          `translateX(calc(-${currentCard * cardWidth}px - ${
            currentCard * gap
          }rem + ${currentPadding}px))`
        )
    }

    /**
     * On call :
     *  - simulate a bounce effect by translating 1/10 of the width of a card in a sense, and then back
     * @param left : boolean
     */
    const bounce = (left?: boolean): void => {
      if (cardWidth) {
        setTransitionDuration(defaultTransitionDuration)
        setTranslation(
          `translateX(calc(-${currentCard * cardWidth}px - ${
            currentCard * gap
          }rem + ${currentPadding}px ${left ? '-' : '+'} ${cardWidth / 10}px))`
        )

        timerRef &&
          (timerRef.current = setTimeout(() => {
            setTranslation(
              `translateX(calc(-${currentCard * cardWidth}px - ${
                currentCard * gap
              }rem + ${currentPadding}px`
            )
          }, 300))
      }
    }

    /**
     * On call :
     *  - check if the current card is not already the last one
     *  - check the distance of the swipe, if it's higher than half a card's width, add 1 to currentCard state
     *  - check the velocity of the swipe, if it's higher than the limit, add 1 to currentCard state by 0.1 velocity over the limit, otherwise add 1
     * @param distance : number
     * @param elpasedTime : number
     */
    const onSwipeLeft = (distance: number, elapsedTime: number): void => {
      // Utils store util to show that the user is swiping
      setUserSwiping()
      const velocity = Math.abs(distance / elapsedTime)
      setTransitionDuration(defaultTransitionDuration)
      if (currentCard !== nbCards - 1 && cardWidth) {
        if (Math.abs(distance) > cardWidth / 2) {
          setCurrentCard((card) => card + 1)
        } else {
          const velocityDifference = Math.round(
            (velocity - velocityBreakpoint) * 10
          )
          const nextCard =
            currentCard + (velocityDifference > 0 ? velocityDifference : 1)

          setCurrentCard(nextCard < nbCards ? nextCard : nbCards - 1)
        }
      } else {
        distance === 1000 && elapsedTime === 1000
          ? bounce(true)
          : resetPosition()
      }
    }

    /**
     * On call :
     *  - check if the current card is not already the first one
     *  - check the distance of the swipe, if it's higher than half a card's width, substract 1 from currentCard state
     *  - check the velocity of the swipe, if it's higher than the limit, substract 1 to currentCard state by 0.1 velocity over the limit, otherwise substract 1
     * @param distance : number
     * @param elpasedTime : number
     */
    const onSwipeRight = (distance: number, elapsedTime: number): void => {
      // Utils store util to show that the user is swiping
      setUserSwiping()
      const velocity = Math.abs(distance / elapsedTime)
      setTransitionDuration(defaultTransitionDuration)
      if (currentCard !== 0 && cardWidth) {
        if (Math.abs(distance) > cardWidth / 2) {
          setCurrentCard((card) => card - 1)
        } else {
          const velocityDifference = Math.round(
            (velocity - velocityBreakpoint) * 10
          )
          const nextCard =
            currentCard - (velocityDifference > 0 ? velocityDifference : 1)
          setCurrentCard(nextCard >= 0 ? nextCard : 0)
        }
      } else {
        distance === 1000 && elapsedTime === 1000 ? bounce() : resetPosition()
      }
    }

    /**
     * On call :
     *  - check if the translation is available (inside allowed bounds)
     *  - if available, set transitionDuration state to '0ms' and then update translation state to the default distance - received distance param
     * @param distance : number
     */
    const onSwiping = (distance: number): void => {
      const available =
        currentCard === 0 || currentCard === nbCards - 1
          ? checkSwipingAvailable(distance, gap)
          : true
      if (cardWidth !== undefined) {
        setTransitionDuration('0ms')
        if (available) {
          if (
            currentCard !== undefined &&
            cardHeight !== undefined &&
            currentPadding !== undefined
          ) {
            setTranslation(
              `translateX(calc(-${currentCard * cardWidth}px - ${
                currentCard * gap
              }rem + ${currentPadding}px - ${distance}px))`
            )
          }
        } else {
          const bounceLimit = cardWidth / 3
          if (Math.abs(distance) <= bounceLimit / 2) {
            setTranslation(
              `translateX(calc(-${currentCard * cardWidth}px - ${
                currentCard * gap
              }rem + ${currentPadding}px - ${
                distance * (1 - Math.abs(distance) / bounceLimit)
              }px))`
            )
          }
        }
      }
    }

    /**
     * On call :
     *  - calculate the max length allowed
     *  - calculate the future length depending on the distance
     *  - check if the future length is inside the available bounds and return a boolean
     * @param distance : number
     * @param gap : number
     * @returns boolean
     */
    const checkSwipingAvailable = (distance: number, gap: number): boolean => {
      if (currentCard !== undefined && cardWidth && currentPadding) {
        const maxLength =
          (nbCards - 1) * cardWidth + (nbCards - 1) * gap + currentPadding
        const currentLength =
          currentCard * cardWidth +
          currentCard *
            gap *
            parseFloat(getComputedStyle(document.documentElement).fontSize) +
          currentPadding +
          distance

        return !(currentLength < currentPadding || currentLength > maxLength)
      }

      return false
    }

    /**
     * On call :
     *  - call onSwipeLeft method
     * @param distance : number
     */
    const onSwipeTop = (distance: number, elapsedTime: number): void => {
      onSwipeLeft(distance, elapsedTime)
    }

    /**
     * On call :
     *  - call onSwipeRight method
     * @param distance : number
     */
    const onSwipeBottom = (distance: number, elapsedTime: number): void => {
      onSwipeRight(distance, elapsedTime)
    }

    /**
     * On click event fire :
     *  - set transitionDuration state to default value
     *  - update currentCard state with the key parameter
     * @param key : number
     */
    const handleClick = (key: number): void => {
      // Utils store util to show that the user is swiping
      setUserSwiping()
      setTransitionDuration(defaultTransitionDuration)
      setCurrentCard(key)
    }

    /**
     * On wheel event fire :
     *  - check which axis has been triggered (x or y) to get its value
     *  - set wheeling state to a positive or negative unique value (here Date.now() is used as unique)
     * @param delta : number
     */
    const handleWheel = (delta): void => {
      if (delta !== undefined) {
        // Utils store util to show that the user is swiping
        setUserSwiping()
        setWheeling(delta > 0 ? Date.now() : -Date.now())
      }
    }

    return (
      <React.Fragment>
        {/* Title if carouselgrid mode */}
        {gridTitle && (
          <div
            className={`rf-w-full rf-text-left rf-title-large-size-auto ${
              mode === Mode.vocal
                ? 'rf-text-truewhite rf-text-shadow-black'
                : 'rf-text-textModePanelConversationBot'
            }`}
            style={{
              paddingLeft: currentPadding
            }}
          >
            {gridTitle}
          </div>
        )}
        <animated.div
          ref={divRef}
          className='rf-w-full rf-flex rf-flex-col'
          style={{
            visibility: draft ? 'hidden' : 'visible',
            pointerEvents: 'all',
            ...spring
          }}
        >
          <Wheeler
            className='rf-w-full'
            handleWheel={handleWheel}
            xAxisOnly={true}
          >
            <Swiper
              direction={direction || 'horizontal'}
              onSwiping={onSwiping}
              onSwipeLeft={onSwipeLeft}
              onSwipeRight={onSwipeRight}
              onSwipeTop={onSwipeTop}
              onSwipeBottom={onSwipeBottom}
              className={
                direction === 'vertical'
                  ? 'rf-flex rf-justify-center'
                  : mode === Mode.vocal && !isMobile
                  ? history
                    ? 'rf-opacity-gradient-l-thin'
                    : 'rf-opacity-gradient-l'
                  : ''
              }
            >
              <div
                ref={ref}
                className={`rf-relative ${
                  direction === 'vertical' ? 'rf-h-max' : 'rf-w-max'
                } ${
                  enableTranslation ? '' : 'rf-m-auto'
                } rf-transition-transform`}
                style={{
                  display: 'grid',
                  gridTemplateColumns:
                    direction === 'vertical'
                      ? `${cardWidth}px`
                      : gridTemplateColumn,
                  gridTemplateRows: gridTemplateRow,
                  gap: isMobile ? '0.3rem' : '1rem',
                  transitionTimingFunction: 'linear',
                  transitionDuration: transitionDuration,
                  transform: enableTranslation ? translation : ''
                }}
              >
                {children}
              </div>
            </Swiper>
          </Wheeler>

          {/* Buttons under the carousel for direct access to a specific card */}
          {!draft && (
            <div className='rf-w-full rf-py-3 rf-flex rf-flex-row rf-justify-center rf-gap-1'>
              {React.Children.map(children, (_child, key) => {
                return (
                  <button
                    key={key}
                    className={`rf-w-3 rf-h-3 rf-border rf-border-trueblack rf-rounded-half ${
                      key === currentCard ? 'rf-bg-primary' : 'rf-bg-truewhite'
                    }`}
                    onClick={(): void => handleClick(key)}
                  />
                )
              })}
            </div>
          )}
        </animated.div>
      </React.Fragment>
    )
  }
)

export default Carousel
