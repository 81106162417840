{
  "1": "hjemmeside",
  "2": "direktesendt nyhetskanal",
  "3": "Værmelding",
  "4": "Nødnumre",
  "1000": "historisk",
  "1001": "tutorial",

  "common": {
    "all": "alle",
    "back": "rug",
    "close": "sluiten",
    "continue": "doorgaan",
    "download": "downloaden",
    "downloadsheet": "download dit blad",
    "downloadsheetmobile": "download dit blad op uw smartphone",
    "filter": "Filter",
    "history": "historisch",
    "lang": "TALEN",
    "menu": "menu",
    "mode": "mode",
    "needhelp": "Hulp nodig?",
    "parameters": "instellingen",
    "print": "Afdrukken",
    "printsheet": "Druk dit blad af",
    "result": "resultaat",
    "results": "resultaten",
    "singleLang": "taal",
    "sound": "zijn",
    "submit": "indienen",
    "subtitle": "ondertitel",
    "tutorial": "zelfstudie",
    "validate": "bevestigen"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "politie",
    "firefighter": "brandweerlieden",
    "general": "algemeen",
    "hospital": "Ziekenhuis",
    "homeless": "Dakloos",
    "poison": "Vergifcontrolecentrum",
    "emergency": "Hulpdiensten"
  },

  "error": {
    "title": "er is een fout opgetreden",
    "button": "Start de applicatie opnieuw"
  },

  "history": {
    "title": "gespreksgeschiedenis"
  },

  "inputs": {
    "choose": "Kies een antwoord"
  },

  "language": {
    "change": "verander de taal",
    "select": "selecteer de taal van uw keuze"
  },

  "link": {
    "buttontext": "Bekijk het document",
    "goto": "Ga naar adres",
    "click": "Klik hier",
    "scanQR": "door de QRCode te scannen",
    "scanQRTile": "Scan de QR-code om te ontdekken"
  },

  "loader": {
    "loader": {
      "optimal": "Voor een optimale ervaring,",
      "classic": "Voor een meer klassieke ervaring,",
      "vocal": "Ik spreek oog in oog met de agent.",
      "text": "Ik communiceer schriftelijk met de makelaar."
    },
    "modes": {
      "vocal": "vocale modus",
      "text": "tekstmodus"
    },
    "loaderValidation": "Begin een gesprek",
    "locale": "taal controleren",
    "supported": "ondersteunde talen controleren",
    "addressdata": "het ophalen van gegevens"
  },

  "menu": {
    "message": "Voer uw bericht in",
    "allowMicrophone": "Laat uw browser uw microfoon gebruiken om door te gaan",
    "backconversation": "terug naar het gesprek",
    "answer": " beantwoordt uw vragen"
  },

  "mobileLandscapePreventer": {
    "firstline": "Voor een optimale ervaring,",
    "secondline": "draai je scherm om"
  },

  "news": {
    "warningtext": "U krijgt toegang tot de nieuwszender, het geluid op uw apparaat wordt geactiveerd",
    "live": "live",
    "continuous": "doorlopend"
  },

  "poi": {
    "comment": "klant beoordeling",
    "route": "weg",
    "nodata": "Er is geen inhoud die overeenkomt met uw zoekopdracht",
    "openinghours": "Openingstijden",
    "prices": "Prijzen",
    "paymentmethods": "Betaalmethoden",
    "numberofplaces": "aantal plaatsen",
    "openroutemobile": "open Google Maps-route op smartphone",
    "FoodEstablishment": "Waar te eten",
    "LodgingBusiness": "Waar te slapen",
    "TouristDestination": "Wat te zien",
    "TouristAttraction": "Wat moeten we doen",
    "Product": "Regionale producten",
    "Service": "Diensten",
    "Event": "Evenementen",
    "City": "Steden",
    "Wine": "Wijnen"
  },

  "suggestion": {
    "consult": "Uitchecken",
    "thetutorial": "de tutorial"
  },

  "template": {
    "reset": "alles resetten",
    "showresults": "Laat de resultaten zien",
    "suggestiontitle": "Zoeksuggesties",
    "reminder": "Vergeet niet dat ik je vragen kan beantwoorden."
  }
}
