import React, { useEffect, useState } from 'react'
import { useRetorikStore, setBaseAddress } from '../Contexts/retorikStore'
import { fetchSupportedLanguages } from '../Contexts/localeStore'
import {
  setGDPRDisplay,
  setGDPRUserConsent,
  setGDPRWindowData
} from '../Contexts/utilsStore'

import {
  RetorikMainComponentConfiguration,
  RetorikMainComponentProps
} from '../../models/retorikTypes'
import { AvailableViews, ContainerParent } from '../../models/enums'
import { DEFAULT_VIEWS } from '../../models/constants'
import getThemeColors, { deepMerge } from '../../utils/getThemeColors'
import { fetchEmergencyData } from '../../utils/fetchEmergencyData'
import { getRetorikGDPR } from '../../utils/getGDPR'
import getRetorikConfigs from '../../utils/getConfigs'
import { EmergencyTypes } from '../../models/emergencyTypes'

import RetorikContainer from './RetorikContainer'
import Loader from '../Loader/Loader'
import getBaseAddress from '../../utils/getBaseAddress'

interface RetorikConfigHandlerProps extends RetorikMainComponentProps {
  parent: number
}

const RetorikConfigHandler = ({
  config,
  viewsConfig,
  chatbotData,
  chatbotDataWidget,
  agentData,
  ponyfillFactoryCredentials,
  customVoice,
  colors,
  skipLoader,
  defaultMode,
  GDPR,
  addressData,
  externalEventHandler,
  externalComponents,
  userData,
  width,
  height,
  menuConfig,
  parent
}: RetorikConfigHandlerProps) => {
  const baseAddress = useRetorikStore((state) => state.baseAddress)
  const [fetchedConfig, setFetchedConfig] =
    useState<RetorikMainComponentConfiguration>()
  const [mainConfig, setMainConfig] = useState<
    RetorikMainComponentConfiguration | undefined
  >(undefined)
  const [isConfigUpdated, setIsConfigUpdated] = useState<boolean>(false)

  /**
   * Check if emergency data can be retrieved to remove the button in menu if no data available
   * @param configuration RetorikMainComponentConfiguration
   */
  const checkEmergencyAndSetConfig = async (
    configuration: RetorikMainComponentConfiguration
  ): Promise<void> => {
    const data: EmergencyTypes = await fetchEmergencyData(baseAddress)

    const tempConfiguration = { ...configuration }

    if (!data) {
      const views = tempConfiguration.config?.baseMenu || DEFAULT_VIEWS
      const tempBaseMenu = views.filter(
        (menu) =>
          // @ts-ignore : error on menu.view that doesn't exist in type CustomMenu
          !menu.view || menu.view !== AvailableViews.emergency
      )
      tempConfiguration.config &&
        (tempConfiguration.config.baseMenu = tempBaseMenu)
    }

    setMainConfig(tempConfiguration)
    setIsConfigUpdated(true)
  }

  const fetchConfigurationFromRetorikStudio = async (
    baseAddress: string
  ): Promise<void> => {
    const fetchedConfig = await getRetorikConfigs(
      {
        config,
        viewsConfig,
        chatbotData,
        chatbotDataWidget,
        agentData,
        ponyfillFactoryCredentials,
        customVoice,
        colors,
        skipLoader,
        defaultMode,
        GDPR,
        addressData,
        externalEventHandler,
        externalComponents,
        userData,
        width,
        height,
        menuConfig
      },
      baseAddress
    )
    console.log('Retorik Framework > configuration fetched from Retorik Studio')
    fetchedConfig && setFetchedConfig(fetchedConfig)
  }

  const checkGDPR = async (baseAddress: string): Promise<void> => {
    const data = await getRetorikGDPR({
      configurationData: GDPR,
      baseAddress: baseAddress
    })

    // Set GDPR data in utilsStore
    data.data && setGDPRWindowData(data.data)
    setGDPRDisplay(data.displayGDPR)
    typeof data.userConsent === 'boolean' &&
      setGDPRUserConsent(data.userConsent)
  }

  /**
   * Process addressData to get the baseAddress and then :
   * - fetch configuration and save it
   * - get GDPR data and store them
   * - fetch available languages and store them
   * - set baseAddress in store
   */
  useEffect(() => {
    if (addressData && (addressData.baseURI || addressData.tenant)) {
      const baseAddress = getBaseAddress(addressData)
      fetchConfigurationFromRetorikStudio(baseAddress)
      checkGDPR(baseAddress)
      fetchSupportedLanguages(baseAddress)

      setBaseAddress(baseAddress)
    }
  }, [addressData])

  useEffect(() => {
    if (fetchedConfig) {
      const {
        skipLoader: _skipLoader,
        defaultMode: _defaultMode,
        config: _config,
        viewsConfig: _viewsConfig,
        chatbotData: _chatbotData,
        chatbotDataWidget: _chatbotDataWidget,
        agentData: _agentData,
        addressData: _addressData,
        ponyfillFactoryCredentials: _ponyfillFactoryCredentials,
        customVoice: _customVoice,
        colors: _colors
      } = fetchedConfig

      const mergedMainConfig: RetorikMainComponentConfiguration = {
        config: config !== undefined ? deepMerge(config, _config) : _config,
        viewsConfig:
          viewsConfig !== undefined
            ? deepMerge(viewsConfig, _viewsConfig)
            : _viewsConfig,
        chatbotData:
          chatbotData !== undefined
            ? { ..._chatbotData, ...chatbotData }
            : _chatbotData,
        chatbotDataWidget:
          chatbotDataWidget !== undefined
            ? {
                ..._chatbotDataWidget,
                ...chatbotDataWidget
              }
            : _chatbotDataWidget,
        agentData: agentData !== undefined ? agentData : _agentData,
        ponyfillFactoryCredentials:
          ponyfillFactoryCredentials !== undefined
            ? ponyfillFactoryCredentials
            : _ponyfillFactoryCredentials,
        customVoice:
          customVoice !== undefined
            ? { ..._customVoice, ...customVoice }
            : _customVoice,
        colors:
          colors !== undefined
            ? deepMerge(colors, getThemeColors(_colors))
            : getThemeColors(_colors),
        skipLoader: skipLoader !== undefined ? skipLoader : _skipLoader,
        defaultMode: defaultMode !== undefined ? defaultMode : _defaultMode,
        // Data not retrieved from Retorik Studio
        addressData: _addressData,
        userData: userData,
        externalEventHandler: externalEventHandler,
        externalComponents: externalComponents,
        width: width,
        height: height
      }

      // Prevent menus from being copied again after each modification of configuration
      if (mergedMainConfig.config) {
        mergedMainConfig.config.baseMenu =
          config?.baseMenu || menuConfig?.baseMenu
        mergedMainConfig.config.customMenu =
          config?.customMenu || menuConfig?.customMenu
        mergedMainConfig.config.subMenu = config?.subMenu || menuConfig?.subMenu
      }

      checkEmergencyAndSetConfig(mergedMainConfig)
    }
  }, [
    config,
    viewsConfig,
    chatbotData,
    chatbotDataWidget,
    agentData,
    ponyfillFactoryCredentials,
    customVoice,
    colors,
    skipLoader,
    defaultMode,
    GDPR,
    addressData,
    externalEventHandler,
    externalComponents,
    userData,
    width,
    height,
    menuConfig,
    fetchedConfig
  ])

  return mainConfig ? (
    <RetorikContainer parent={parent} {...mainConfig} />
  ) : (
    <Loader
      className={isConfigUpdated ? 'rf-animate-loaderFadeOut' : ''}
      parent={parent}
      width={width}
      height={height}
      fullSize={
        !!(parent !== ContainerParent.widget && config?.fullSize !== false)
      }
    />
  )
}

export default RetorikConfigHandler
