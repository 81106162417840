import React, { useEffect, useState, useRef } from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useList } from '../Contexts/ListContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { useViewStore } from '../Contexts/viewStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import {
  useUtilsStore,
  setLastListActivity,
  setListOpened
} from '../Contexts/utilsStore'
import { DeviceType, Mode } from '../../models/enums'
import Template from '../Templates/FullScreenRemoteClosing'
import Title from './Parts/Title'
import FilterLarge from './Parts/FilterLarge'
import FilterMobile from './Parts/FilterMobile'
import ChosenItem from './Parts/Filter/ChosenItem'
import ListContainer from './Parts/ListContainer'
import Suggestions from './Parts/Suggestions'
import { useSpring, easings } from '@react-spring/web'
import preventEvents from '../../utils/preventEvents'
import { sendEvent } from '../Contexts/directLineStore'

const springEnterDuration = 1500
const springLeaveDuration = 2500

const List = ({ activity, onClose }): JSX.Element => {
  const mode = useRetorikStore((state) => state.mode)
  const isMobile = useViewStore((state) => state.isMobile)
  const isWidget = useViewStore((state) => state.isWidget)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const { content, filters, selectedFilters, setContent } = useList()
  const locale = useLocaleStore((state) => state.locale)
  const lastListActivity = useUtilsStore((state) => state.lastListActivity)
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState<boolean>(false)
  const [largeFilterOpenedKey, setLargeFilterOpenedKey] = useState<
    number | undefined
  >(undefined)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [spring, api] = useSpring(() => ({
    from: {
      transform: 'translateX(0%)'
    }
  }))
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const [abortController, setAbortController] =
    useState<AbortController | null>(null)

  const handleOpen = (): void => {
    setListOpened(true)
    api.start({
      from: {
        transform: 'translateX(-100%)'
      },
      to: {
        transform: 'translateX(0%)'
      },
      config: {
        duration: springEnterDuration,
        easing: easings.easeInBack
      }
    })
  }

  const handleClose = (): void => {
    setListOpened(false)
    api.start({
      from: {
        transform: 'translateX(0%)'
      },
      to: {
        transform: 'translateX(-100%)'
      },
      config: {
        duration: springLeaveDuration,
        easing: easings.easeOutExpo
      }
    })

    if (timerRef) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        sendEvent('Davi.CloseWindow', null)
        sendEvent('Davi.CloseWindowList', null)
        onClose?.()
        setContent(undefined)
      }, springLeaveDuration)
    }
  }

  useEffect(() => {
    if (activity && activity.id !== lastListActivity) {
      setContent(activity)

      if (timerRef.current) {
        clearTimeout(timerRef.current)
        handleOpen()
      }
    }

    return (): void => {
      activity?.id && setLastListActivity(activity.id)
    }
  }, [activity])

  /**
   * On call :
   *  - scroll to the scrollRef HTML element at the top of the view
   */
  const scrollToRef = (): void => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  useEffect(() => {
    if (divRef?.current && isWidget) {
      if (currentDeviceType === DeviceType.widget) {
        const ab = new AbortController()
        setAbortController(ab)
        preventEvents(null, divRef, ab.signal)
      } else {
        abortController?.abort()
        setAbortController(null)
      }
    }
  }, [divRef?.current, currentDeviceType])

  /**
   * On content change :
   *  - call scrollToRef method
   */
  useEffect(() => {
    const timer =
      content && isMobile && content.label !== 'Davi.NextPageContent'
        ? setTimeout(() => {
            scrollToRef()
          }, 100)
        : null

    return (): void => {
      timer && clearTimeout(timer)
    }
  }, [content])

  useEffect(() => {
    handleOpen()

    return (): void => {
      setListOpened(false)
      timerRef?.current && clearTimeout(timerRef.current)
    }
  }, [])

  return content ? (
    <Template
      className='large:rf-px-16 rf-flex rf-flex-col large:rf-h-full'
      handleClose={handleClose}
      onClick={
        isMobile
          ? (): void => {}
          : (): void => setLargeFilterOpenedKey(undefined)
      }
      remoteCloseButtonZIndex='rf-z-util'
      animation={spring}
    >
      {isMobile && (
        <FilterMobile
          setFiltersOpened={setMobileFiltersOpened}
          handleClose={handleClose}
          hasFilters={!!filters.length}
        />
      )}

      <div
        ref={divRef}
        className='rf-w-full rf-overflow-y-scroll rf-scrollbar-hidden'
      >
        <div ref={scrollRef} />
        <div
          className={`${
            mobileFiltersOpened ? 'rf-hidden' : 'rf-flex'
          } rf-flex-col rf-items-start large:rf-pt-16 large:rf-h-full large-vertical:rf-pt-[20vh]`}
        >
          {content.text && (
            <Title
              className='rf-ml-2 rf-mt-8 large:rf-ml-0 large:rf-mt-0'
              title={capitalizeFirstLetter(content.text, locale)}
              numberOfResults={content.channelData?.numberOfResults || 0}
            />
          )}

          {!isMobile && filters.length ? (
            <FilterLarge
              openedFilterKey={largeFilterOpenedKey}
              setOpenedFilterKey={setLargeFilterOpenedKey}
            />
          ) : (
            <React.Fragment />
          )}

          {isMobile && (
            <div className='rf-flex rf-flex-row rf-mx-2 rf-gap-2 rf-mb-2 rf-flex-wrap'>
              {selectedFilters?.map((chosenFilter, key1) => {
                return chosenFilter.filterItems.map((item, key2) => {
                  return (
                    <ChosenItem
                      className='rf-px-2'
                      key={`${key1}-${key2}`}
                      filterItem={item}
                      parent={{
                        id: chosenFilter.id,
                        title: chosenFilter.title
                      }}
                    />
                  )
                })
              })}
            </div>
          )}

          <ListContainer
            hasFilterOrSuggestedAction={
              filters.length > 0 ||
              content.suggestedActions?.actions?.length > 0
            }
            numberOfResults={content.channelData?.numberOfResults || 0}
          />

          {content.suggestedActions?.actions?.length ? (
            <Suggestions
              className='rf-px-2 large:rf-px-0 large:rf-mb-4'
              suggestions={content.suggestedActions.actions}
              handleClose={handleClose}
            />
          ) : (
            <React.Fragment />
          )}

          {isMobile ? (
            <div className={mode === Mode.text ? 'rf-mb-28' : 'rf-mb-16'} />
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    </Template>
  ) : (
    <React.Fragment />
  )
}

export default List
