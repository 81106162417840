{
  "1": "ホームページ",
  "2": "ライブニュースチャンネル",
  "3": "天気予報",
  "4": "緊急電話番号",
  "1000": "歴史的な",
  "1001": "チュートリアル",

  "common": {
    "all": "全て",
    "back": "戻る",
    "close": "近い",
    "continue": "続く",
    "download": "ダウンロード",
    "downloadsheet": "このシートをダウンロード",
    "downloadsheetmobile": "このシートをスマートフォンにダウンロード",
    "filter": "フィルター",
    "history": "歴史的な",
    "lang": "言語",
    "menu": "メニュー",
    "mode": "ファッション",
    "needhelp": "助けが必要？",
    "parameters": "設定",
    "print": "印刷する",
    "printsheet": "このシートを印刷する",
    "result": "結果",
    "results": "結果",
    "singleLang": "言語",
    "sound": "彼女",
    "submit": "提出する",
    "subtitle": "字幕",
    "tutorial": "チュートリアル",
    "validate": "検証"
  },

  "emergency": {
    "samu": "S.A.M.U",
    "police": "警察",
    "firefighter": "消防士",
    "general": "一般的な",
    "citypolice": "地元警察",
    "hospital": "病院",
    "homeless": "ホームレス（無料通話）",
    "poison": "毒物管理センター",
    "emergency": "ヨーロッパの緊急事態"
  },

  "error": {
    "title": "エラーが発生しました",
    "button": "アプリケーションを再起動する"
  },

  "history": {
    "title": "会話履歴"
  },

  "inputs": {
    "choose": "答えを選択してください"
  },

  "language": {
    "change": "言語を変える",
    "select": "使用したい言語を選択してください"
  },

  "link": {
    "buttontext": "ドキュメントを見る",
    "goto": "住所に移動",
    "click": "ここをクリック",
    "scanQR": "QRコードをスキャンすることで",
    "scanQRTile": "QRコードをスキャンして発見する"
  },

  "loader": {
    "loader": {
      "optimal": "最適なエクスペリエンスを実現するために、",
      "classic": "よりクラシックな体験をするには、",
      "vocal": "エージェントと対面で話します。",
      "text": "私はエージェントと書面でやり取りします。"
    },
    "modes": {
      "vocal": "ボーカルモード",
      "text": "テキストモード"
    },
    "loaderValidation": "会話を開始する",
    "locale": "言語をチェックする",
    "supported": "サポートされている言語を確認する",
    "addressdata": "データの取得"
  },

  "menu": {
    "message": "メッセージを入力してください",
    "allowMicrophone": "続行するにはブラウザにマイクの使用を許可します",
    "backconversation": "会話に戻ります",
    "answer": " あなたの質問に答えます"
  },

  "mobileLandscapePreventer": {
    "firstline": "最適なエクスペリエンスを実現するために、",
    "secondline": "画面を反転してください"
  },

  "news": {
    "warningtext": "ニュースチャンネルにアクセスすると、デバイスのサウンドがアクティブになります",
    "live": "ライブ",
    "continuous": "継続的に"
  },

  "poi": {
    "comment": "カスタマーレビュー",
    "route": "道",
    "nodata": "検索に一致するコンテンツはありません",
    "openinghours": "営業時間",
    "prices": "価格",
    "paymentmethods": "お支払い方法",
    "numberofplaces": "場所の数",
    "openroutemobile": "スマホでGoogleマップのルートを開く",
    "FoodEstablishment": "どこで食べます",
    "LodgingBusiness": "どこで寝るか",
    "TouristDestination": "見るべきもの",
    "TouristAttraction": "何をするか",
    "Product": "地元の特産品",
    "Service": "サービス",
    "Event": "イベント",
    "City": "都市",
    "Wine": "ワイン"
  },

  "suggestion": {
    "consult": "チェックアウト",
    "thetutorial": "チュートリアル"
  },

  "template": {
    "reset": "すべてリセット",
    "showresults": "結果を表示する",
    "suggestiontitle": "検索候補",
    "reminder": "忘れないでください、\n あなたの質問に答えます。"
  }
}
