import { DEFAULT_LANGUAGE_DATA } from '../models/constants'
import type { AvailableLanguages } from '../models/types'

export default async function fetchAvailableLanguages(
  baseAddress: string
): Promise<AvailableLanguages> {
  const fullAddress = `${baseAddress}api/graphql?query={siteCultures{culture,default}}`
  const languages: AvailableLanguages = await fetch(fullAddress)
    .then((res) => res.json())
    .then((json) => {
      return processJson(json)
    })
    .catch((error) => {
      console.log(error)
      return DEFAULT_LANGUAGE_DATA
    })

  return languages
}

/* The data received from the fetch are as follows
{
  data: {
    siteCultures: [
      {
        culture: "fr-FR",
        default: true
      },
      {
        culture: "en-US",
        default: false
      },
      {
        culture: "de-DE",
        default: false
      }
    ]
  }
}
*/
const processJson = (json): AvailableLanguages => {
  if (!json.data?.siteCultures || !json.data.siteCultures?.length) {
    return DEFAULT_LANGUAGE_DATA
  }

  // Filter data to remove sitecultures without culture as <language>-<culture>
  const siteCultures = json.data.siteCultures.filter((siteCulture) =>
    siteCulture.culture?.includes('-')
  )

  const processedLanguages = {
    all: siteCultures.map((siteCulture) => siteCulture.culture),
    default:
      siteCultures.find((siteCulture) => siteCulture.default)?.culture ||
      siteCultures[0].culture
  }
  return processedLanguages
}
