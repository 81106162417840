import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { getTextWithoutSpeechMarkdown } from '../../../utils/speechMarkdownUtils'
import { checkListDisplay } from '../../../utils/checkListDisplay'

import { RetorikActivity } from '../../../models/activityTypes'
import Attachments from '../../Attachments/Attachments'

interface DisplayNonStreamingProps {
  nonStreamingActivities: Array<RetorikActivity>
  isMobile?: boolean
}

const DisplayNonStreaming = ({
  nonStreamingActivities,
  isMobile
}: DisplayNonStreamingProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const forceMobileView = useRetorikStore(
    (state) => state.configuration.forceMobileView
  )

  return (
    <React.Fragment>
      {nonStreamingActivities.map((activity, key) => {
        const answerTime = activity.localTimestamp
          ? new Date(activity.localTimestamp)
          : activity.timestamp
          ? new Date(activity.timestamp)
          : new Date()

        return (
          <div
            key={key}
            className={`${
              key > 0 && 'rf-pt-2'
            } rf-text-textModePanelConversationBot rf-animate-slideBottom rf-flex rf-flex-col rf-items-start rf-gap-2`}
          >
            {/* Time */}
            {isMobile && (
              <div className='rf-px-4 rf-text-[#9A9A9A]'>
                {answerTime.toLocaleString(locale, {
                  hour: 'numeric',
                  minute: 'numeric'
                })}
              </div>
            )}

            {/* Text */}
            {activity.htmlText ? (
              <div
                className='rf-px-4'
                dangerouslySetInnerHTML={{
                  __html: activity.htmlText
                }}
              />
            ) : activity.text ? (
              <div className='rf-px-4'>
                {getTextWithoutSpeechMarkdown(activity.text)}
              </div>
            ) : (
              <React.Fragment />
            )}

            {/* Attachments */}
            {!!activity?.attachments?.length && !checkListDisplay(activity) && (
              <div
                className={`${forceMobileView ? 'rf-m-auto' : 'rf-w-full'} ${
                  activity.attachments.length <= 1 && 'rf-px-4'
                } rf-flex rf-flex-col rf-gap-2`}
              >
                <Attachments activity={activity} />
              </div>
            )}
          </div>
        )
      })}
    </React.Fragment>
  )
}

export default DisplayNonStreaming
