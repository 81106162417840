import { AddressData } from '../models/types'
import processBaseURI from './studioRetorikAddressFromBaseURI'
import processPrefix from './studioRetorikAddressFromPrefix'

/**
 * Get the base address from the incoming data.
 * The cases are :
 * - addressData.baseURI only : return this baseURI after removing the deprecated data
 * - addressData.baseURI + addressData.tenant : return the concatenation of this 2 values separated by '/'
 * - addressData.tenant only (with or without addressData.prefix) : process the prefix and return the processed data with the tenant
 * @param {AddressData} addressData
 * @returns {string}
 */
const getBaseAddress = (addressData: AddressData): string => {
  if (addressData.baseURI?.length) {
    if (addressData.tenant?.length) {
      return `${addressData.baseURI}/${addressData.tenant}/`
    } else {
      return processBaseURI(addressData.baseURI)
    }
  } else {
    const tempBaseUri = processPrefix(addressData.prefix)
    return `${tempBaseUri}/${addressData.tenant}/`
  }
}

export default getBaseAddress
