import React, { useMemo, useRef } from 'react'
import { useViewStore } from '../Contexts/viewStore'
import { setModalFullscreenContent } from '../Contexts/utilsStore'
import { sendMessage } from '../Contexts/directLineStore'
import useRefDimensions from '../../hooks/useRefDimensions'

interface IFrameProps {
  uri: string
  height?: number | string
  width?: number | string
  title?: string
  actions?: Array<{
    type: string
    value: string
    title: string
  }>
}

const IFrame = ({
  uri,
  height,
  width,
  title,
  actions
}: IFrameProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)
  const currentHeight = useViewStore((state) => state.currentHeight)
  const currentWidth = useViewStore((state) => state.currentWidth)
  const divRef = useRef<HTMLDivElement>(null)
  const dimensions = useRefDimensions(divRef)

  const processedHeight = useMemo<number | string>(() => {
    if (currentHeight > currentWidth) {
      return currentWidth > 960 ? '50%' : '100%'
    }

    return '100%'
  }, [currentHeight, currentWidth])

  const processedWidth = useMemo<number | string>(() => {
    return currentWidth > 960 ? 960 : '100%'
  }, [currentWidth])

  const handleClick = (value: string): void => {
    if (typeof value === 'string') {
      sendMessage(value)
    } else if (typeof value === 'object') {
      sendMessage(undefined, value)
    }
    setModalFullscreenContent(null)
  }

  return (
    <div
      ref={divRef}
      className={`${
        isMobile ? '' : 'rf-py-8'
      } rf-flex rf-flex-col rf-justify-center`}
      style={{
        height: height || processedHeight,
        width: width || processedWidth
      }}
    >
      {/* Title */}
      {title && (
        <div className='rf-w-full rf-py-4 rf-px-4 rf-flex rf-flex-row rf-justify-start rf-items-center rf-gap-4 rf-bg-truewhite rf-rounded-t-lg'>
          {title}
        </div>
      )}

      <iframe
        src={uri}
        height={dimensions.height}
        width={dimensions.width}
        style={{
          backgroundColor: '#FFF'
        }}
      />

      {/* Validation button */}
      {actions?.length && (
        <div className='rf-w-full rf-py-4 rf-flex rf-flex-row rf-justify-center rf-items-center rf-gap-4 rf-bg-truewhite rf-rounded-b-lg'>
          {actions.map((action, key) => {
            return (
              <button
                key={key}
                className='rf-text-secondary rf-border rf-border-secondary hover:rf-text-black hover:rf-border-black rf-rounded rf-py-1 large:rf-py-2 rf-px-2 large:rf-px-4 rf-text-size-auto'
                onClick={() => handleClick(action.value)}
              >
                {action.title}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default IFrame
