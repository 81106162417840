import React from 'react'
import { useViewStore } from '../Contexts/viewStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import RemoteContainer from './Remote/RemoteContainer'
import MobileContainer from './Mobile/MobileContainer'
import { LanguageButton } from './Common'

interface MenuDisplayerProps {
  hideMenu?: boolean
}

const MenuDisplayer = ({ hideMenu }: MenuDisplayerProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)
  const displayControls = useViewStore((state) => state.displayControls)
  const configuration = useRetorikStore((state) => state.configuration)

  return !displayControls ? (
    <React.Fragment />
  ) : isMobile ? (
    <MobileContainer hideMenu={!!hideMenu} />
  ) : (
    <React.Fragment>
      <RemoteContainer hideMenu={!!hideMenu} />
      {!configuration.languageChoiceBottom?.setUnderRemote && (
        <LanguageButton />
      )}
    </React.Fragment>
  )
}

export default MenuDisplayer
