/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useViewStore } from '../../Contexts/viewStore'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { askForKioskParametersOpening } from '../../Contexts/utilsStore'

import { FullScreen } from '../../Templates'
import AbsoluteLargeClosingButton from '../../Utils/SubView/AbsoluteLargeClosingButton'
import Logo from '../../Common/Logo'
import DisplayViews from '../ViewsDisplay/DisplayViews'
import DisplaySubViews from '../ViewsDisplay/DisplaySubViews'
import { BorneParametersIcon } from '../../Icons/ParameterIcons'
import Version from './Version'

interface DashboardProps {
  toggleDashboard?: () => void
}

const Dashboard = ({ toggleDashboard }: DashboardProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const isMobile = useViewStore((state) => state.isMobile)

  const handleClick = (): void => {
    toggleDashboard && toggleDashboard()
  }

  /**
   * Called in borne mode to open retorik kiosk parameters
   */
  const handleClickParameters = (): void => {
    askForKioskParametersOpening()
    toggleDashboard && toggleDashboard()
  }

  return isMobile ? (
    <div className='rf-w-full rf-px-5 rf-pt-4 rf-flex rf-flex-col rf-justify-start'>
      <DisplayViews conditionalHandleClick={handleClick} />
    </div>
  ) : (
    <FullScreen>
      <div className='rf-h-full rf-w-full rf-flex rf-justify-center rf-items-center'>
        {/* Logo */}
        <Logo />

        <div className='rf-relative rf-h-full rf-max-w-7/10 rf-flex rf-flex-col rf-items-start rf-gap-8'>
          {/* Available views */}
          <div className='rf-min-h-2/5 rf-py-4 rf-mt-auto rf-flex rf-flex-col rf-gap-8 rf-overflow-y-scroll rf-scrollbar-hidden'>
            <DisplayViews handleClick={toggleDashboard} />
          </div>

          {/* Available subviews */}
          <div className='rf-h-3/10 rf-flex rf-flex-row rf-items-center rf-flex-wrap rf-gap-8'>
            <DisplaySubViews handleClick={toggleDashboard} />
          </div>
        </div>

        {/* Parameter button for Retorik Kiosk */}
        {isUsedOnBorne ? (
          <button
            // @ts-ignore
            part='retorik-framework-parameters'
            className='rf-absolute rf-left-4 rf-bottom-4 rf-h-8 rf-flex rf-flex-row rf-items-center'
            onClick={handleClickParameters}
          >
            <BorneParametersIcon color='#A4A7AF' />
            <p className='rf-uppercase rf-ml-1 rf-text-xsl rf-text-[#A4A7AF]'>
              {translation.common.parameters}
            </p>
          </button>
        ) : (
          <Version />
        )}
      </div>

      {/* Closing button */}
      <AbsoluteLargeClosingButton
        dashboardVisible={true}
        onClick={handleClick}
      />
    </FullScreen>
  )
}

export default Dashboard
