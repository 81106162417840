import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { FactSet } from 'adaptivecards/lib/card-elements'
import CustomFactSet from '../FactSet'

export class FactSetClass extends FactSet {
  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    const props = {
      facts: this.facts
    }
    return <CustomFactSet {...props} />
  }
}
