import { Fact } from 'adaptivecards'
import React from 'react'

interface FactSetProps {
  facts?: Array<Fact>
}

const FactSet = ({ facts }: FactSetProps): JSX.Element => {
  return facts?.length ? (
    <div className='rf-w-full rf-text-cardFrameText rf-text-size-auto'>
      <table className='rf-border-0 rf-border-none rf-border-collapse rf-overflow-hidden rf-box-border'>
        {facts.map((fact, key) => {
          return (
            <tr key={key}>
              <td className='rf-font-bold'>{fact.name}</td>
              <td className='rf-px-2'>{fact.value}</td>
            </tr>
          )
        })}
      </table>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default FactSet
