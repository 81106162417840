{
  "1": "الصفحة الرئيسية",
  "2": "قناة اخبارية حية",
  "3": "تقرير حالة ألطقس",
  "4": "أرقام الطوارئ",
  "1000": "تاريخي",
  "1001": "درس تعليمي",

  "common": {
    "all": "الجميع",
    "back": "خلف",
    "close": "اغلاق",
    "continue": "يكمل",
    "download": "تحميل",
    "downloadsheet": "قم بتنزيل هذه الورقة",
    "downloadsheetmobile": "قم بتنزيل هذه الورقة على الهاتف الذكي",
    "filter": "منقي",
    "history": "تاريخي",
    "lang": "اللغات",
    "menu": "القائمة",
    "mode": "موضة",
    "needhelp": "تحتاج مساعدة؟",
    "parameters": "الإعدادات",
    "print": "مطبعة",
    "printsheet": "اطبع هذه الورقة",
    "result": "نتيجة",
    "results": "نتائج",
    "singleLang": "لغة",
    "sound": "ها",
    "submit": "يُقدِّم",
    "subtitle": "العنوان الفرعي",
    "tutorial": "درس تعليمي",
    "validate": "التحقق من صحة"
  },

  "emergency": {
    "samu": ".S.A.M.U",
    "police": "شرطة",
    "firefighter": "رجال الاطفاء",
    "general": "عام",
    "citypolice": "الشرطة المحلية",
    "hospital": "مستشفى",
    "homeless": "بلا مأوى (مكالمة مجانية)",
    "poison": "مركز مراقبة السموم",
    "emergency": "حالات الطوارئ الأوروبية"
  },

  "error": {
    "title": "حدث خطأ",
    "button": "أعد تشغيل التطبيق"
  },

  "history": {
    "title": "تاريخ المحادثة"
  },

  "inputs": {
    "choose": "اختر إجابة"
  },

  "language": {
    "change": "تغيير اللغة",
    "select": "حدد اللغة التي تريد استخدامها"
  },

  "link": {
    "buttontext": "عرض الوثيقة",
    "goto": "اذهب إلى العنوان",
    "click": "انقر هنا",
    "scanQR": "عن طريق مسح رمز الاستجابة السريعة",
    "scanQRTile": "مسح رمز الاستجابة السريعة لاكتشاف"
  },

  "loader": {
    "loader": {
      "optimal": "للحصول على تجربة مثالية،",
      "classic": "لتجربة أكثر كلاسيكية،",
      "vocal": "أنا أتحدث وجها لوجه مع الوكيل.",
      "text": "أتواصل كتابيًا مع الوكيل."
    },
    "modes": {
      "vocal": "الوضع الصوتي",
      "text": "وضع النص"
    },
    "loaderValidation": "ابدأ المحادثة",
    "locale": "فحص اللغة",
    "supported": "التحقق من اللغات المدعومة",
    "addressdata": "استرجاع البيانات"
  },

  "menu": {
    "message": "أدخل رسالتك",
    "allowMicrophone": "اسمح لمتصفحك باستخدام الميكروفون للمتابعة",
    "backconversation": "العودة إلى المحادثة",
    "answer": " يجيب على أسئلتك"
  },

  "mobileLandscapePreventer": {
    "firstline": "للحصول على تجربة مثالية،",
    "secondline": "اقلب شاشتك"
  },

  "news": {
    "warningtext": "ستدخل إلى القناة الإخبارية، وسيتم تنشيط الصوت على جهازك",
    "live": "مباشر",
    "continuous": "لنكمل"
  },

  "poi": {
    "comment": "رأي العميل",
    "route": "طريق",
    "nodata": "لا يوجد محتوى يطابق بحثك",
    "openinghours": "ساعات العمل",
    "prices": "الأسعار",
    "paymentmethods": "طرق الدفع",
    "numberofplaces": "عدد الأماكن",
    "openroutemobile": "افتح طريق خريطة جوجل على الهاتف الذكي",
    "FoodEstablishment": "أين تأكل",
    "LodgingBusiness": "أين تنام",
    "TouristDestination": "ماذا ترى",
    "TouristAttraction": "ما يجب القيام به",
    "Product": "منتجات محلية",
    "Service": "خدمات",
    "Event": "الأحداث",
    "City": "مدن",
    "Wine": "النبيذ"
  },

  "suggestion": {
    "consult": "الدفع",
    "thetutorial": "البرنامج التعليمي"
  },

  "template": {
    "reset": "إعادة ضبط الجميع",
    "showresults": "عرض النتائج",
    "suggestiontitle": "اقتراحات البحث",
    "reminder": "لا تنسى \n أستطيع الإجابة على أسئلتك."
  }
}