import type { GDPR } from '../models/types'

interface GetRetorikGDPRProps {
  configurationData?: GDPR
  baseAddress: string
}

interface FetchedGDPRData {
  enableConsent: boolean
  localizationContainer: {
    [key: string]: {
      ConsentTitle?: string | null
      ConsentBody?: string | null
      PrivacyStatementTitle?: string | null
      PrivacyStatementUrl?: string | null
      ConsentAknowledgementAccepted?: string | null
      ConsentAknowledgementRefused?: string | null
    }
  }
  privacyStatementUrl?: string
}

const getRetorikGDPR = async ({
  configurationData,
  baseAddress
}: GetRetorikGDPRProps): Promise<{
  displayGDPR: boolean
  userConsent?: boolean
  data?: GDPR
}> => {
  if (configurationData) {
    if (typeof configurationData.userConsent === 'boolean') {
      return {
        displayGDPR: false,
        userConsent: configurationData.userConsent
      }
    } else {
      return {
        displayGDPR: true,
        data: configurationData
      }
    }
  } else {
    const fullAddress = `${baseAddress}api/gdpr/settings`

    const response: FetchedGDPRData | undefined | null = await fetch(
      fullAddress
    )
      .then((res) => res.json())
      .catch((error) => {
        console.warn(error)
        return undefined
      })

    if (response) {
      // Format response data to get a GDPR-type object
      const responseToData: any = {
        title: {},
        message: {},
        link: {},
        linkURL: undefined,
        buttonAccept: {},
        buttonRefuse: {},
        messageAccepted: {},
        messageRefused: {}
      }

      for (const locale in response.localizationContainer) {
        // Title
        response.localizationContainer[locale]?.ConsentTitle &&
          (responseToData.title[locale] =
            response.localizationContainer[locale]?.ConsentTitle)
        // Message
        response.localizationContainer[locale]?.ConsentBody &&
          (responseToData.message[locale] =
            response.localizationContainer[locale]?.ConsentBody)
        // Link
        response.localizationContainer[locale]?.PrivacyStatementTitle &&
          (responseToData.link[locale] =
            response.localizationContainer[locale]?.PrivacyStatementTitle)
        // Message accepted
        response.localizationContainer[locale]?.ConsentAknowledgementAccepted &&
          (responseToData.messageAccepted[locale] =
            response.localizationContainer[
              locale
            ]?.ConsentAknowledgementAccepted)
        // Message refused
        response.localizationContainer[locale]?.ConsentAknowledgementRefused &&
          (responseToData.messageRefused[locale] =
            response.localizationContainer[
              locale
            ]?.ConsentAknowledgementRefused)
      }

      // LinkURl is the same for all languages
      responseToData.linkURL = response.privacyStatementUrl

      return {
        displayGDPR: response.enableConsent,
        data: responseToData
      }
    }
  }

  return {
    displayGDPR: false,
    userConsent: false
  }
}

export { getRetorikGDPR }
