{
  "1": "主页",
  "2": "实时信息频道",
  "3": "天气",
  "4": "紧急电话号码",
  "1000": "历史",
  "1001": "教程",

  "common": {
    "all": "全部",
    "back": "后退",
    "close": "关闭",
    "continue": "继续",
    "download": "下载",
    "downloadsheet": "下载此表",
    "downloadsheetmobile": "在智能手机上下载此表",
    "filter": "筛选",
    "history": "历史的",
    "lang": "语言",
    "menu": "菜单",
    "mode": "时尚",
    "needhelp": "需要帮忙？",
    "parameters": "设置",
    "print": "打印",
    "printsheet": "打印此表",
    "result": "结果",
    "results": "结果",
    "singleLang": "语言",
    "sound": "她",
    "submit": "提交",
    "subtitle": "字幕",
    "tutorial": "教程",
    "validate": "证实"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "警察",
    "firefighter": "消防员",
    "general": "将军",
    "hospital": "医院",
    "homeless": "无家可归",
    "poison": "毒物控制中心",
    "emergency": "紧急服务"
  },

  "error": {
    "title": "发生了错误",
    "button": "重新启动应用程序"
  },

  "history": {
    "title": "对话历史"
  },

  "inputs": {
    "choose": "选择一个答案"
  },

  "language": {
    "change": "更改语言",
    "select": "选择您要使用的语言"
  },

  "link": {
    "buttontext": "查看文档",
    "goto": "前往地址",
    "click": "点击这里",
    "scanQR": "扫描二维码",
    "scanQRTile": "扫描二维码以发现"
  },

  "loader": {
    "loader": {
      "optimal": "为了获得最佳体验，",
      "classic": "想要更经典的体验，",
      "vocal": "我通过与代理人交谈来沟通。",
      "text": "我通过短信与代理人沟通。"
    },
    "modes": {
      "vocal": "声音模式",
      "text": "文本模式"
    },
    "loaderValidation": "开始对话",
    "locale": "检查语言",
    "supported": "检查支持的语言",
    "addressdata": "检索数据"
  },

  "menu": {
    "message": "输入您的留言",
    "allowMicrophone": "允许您的浏览器使用您的麦克风以继续",
    "backconversation": "回到对话",
    "answer": " 回答你的问题"
  },

  "mobileLandscapePreventer": {
    "firstline": "为了获得最佳体验，",
    "secondline": "翻转你的屏幕"
  },

  "news": {
    "warningtext": "您将访问新闻频道，设备上的声音将被激活",
    "live": "居住",
    "continuous": "不断地"
  },

  "poi": {
    "comment": "客户评论",
    "route": "路",
    "nodata": "没有内容符合您的搜索",
    "openinghours": "营业时间",
    "prices": "价格",
    "paymentmethods": "支付方式",
    "numberofplaces": "名额",
    "openroutemobile": "在智能手机上打开 Google 地图路线",
    "FoodEstablishment": "去哪里吃饭",
    "LodgingBusiness": "睡觉的地方",
    "TouristDestination": "该看什么",
    "TouristAttraction": "该怎么办",
    "Product": "土特产",
    "Service": "服务",
    "Event": "活动",
    "City": "城市",
    "Wine": "葡萄酒"
  },

  "suggestion": {
    "consult": "查看",
    "thetutorial": "教程"
  },

  "template": {
    "reset": "重置全部",
    "showresults": "显示结果",
    "suggestiontitle": "搜索建议",
    "reminder": "别忘了，\n我可以回答你的问题。"
  }
}
