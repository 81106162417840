/* eslint-disable react/jsx-boolean-value */
import React, { useMemo } from 'react'
import type { DirectLineAttachment } from '../../../models/directLine'
import renderAttachment from '../../Middlewares/attachmentMiddleware'
import Card from '../../AnswerPanel/Card'
import Grid from '../../Display/Grid'

interface GridContainerProps {
  title?: string
  attachments: Array<DirectLineAttachment>
  history?: boolean
}

const GridContainer = ({
  title,
  attachments,
  history
}: GridContainerProps): JSX.Element => {
  const processedAttachments = useMemo<Array<DirectLineAttachment>>(() => {
    let tempAttachments = [...attachments]
    tempAttachments = tempAttachments.sort(
      (a: any, b: any) => a.content.index - b.content.index
    )

    return tempAttachments
  }, [attachments])

  return (
    <Grid title={title} history={history}>
      {processedAttachments.map((attachment, key) => {
        return (
          <Card key={key} className='rf-w-full' shadow={true}>
            {renderAttachment({
              attachment: attachment,
              history: history
            })}
          </Card>
        )
      })}
    </Grid>
  )
}

export default GridContainer
