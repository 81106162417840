async function fetchDirectLineToken(address: string): Promise<string> {
  const token = await fetch(address)
    .then((res) => {
      return res.json()
    })
    .catch((error) =>
      console.warn(
        `Retorik Framework > error fetching directline token : ${error}`
      )
    )

  return token
}

export { fetchDirectLineToken }
