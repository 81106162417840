import { create } from 'zustand'
import {
  DirectLine,
  ConnectionStatus,
  Activity
} from 'botframework-directlinejs'

import {
  LegacyDirectLineActivityType,
  LegacyDirectLineStatus
} from '../../models/directLine'
import { setIsWaitingForResponse } from './retorikStore'
import { unlockClickedButton } from './utilsStore'
import {
  addBotEvent,
  addBotMessage,
  addUserEvent,
  addUserMessage,
  checkConversationId,
  setRetrievingConversation,
  useActivityStore
} from './activityStore'
import { useLocaleStore } from './localeStore'
import {
  setCancel,
  checkActivityReplyToId,
  addSpeechRecognitionDynamicGrammar
} from './speechStore'
import useActivityMiddleware from '../Middlewares/activityMiddleware'
import { RetorikActivity } from '../../models/activityTypes'
import type {
  DirectLineCreationData,
  DataAddedToChannelData
} from '../../models/directLine'

interface DirectLineStore {
  directLine: DirectLine | null
  directLineStatus: ConnectionStatus
  connectionStatusSubscription: any
  activitySubscription: any
  userId: string
  externalActivityHandler: ((action: any) => boolean) | undefined | null
  paginationToSend: number
  positionToSend:
    | {
        latitude: number
        longitude: number
      }
    | undefined
  activityWatermark: number
}

const initialState: DirectLineStore = {
  directLine: null,
  directLineStatus: ConnectionStatus.Uninitialized,
  connectionStatusSubscription: null,
  activitySubscription: null,
  userId: '',
  externalActivityHandler: null,
  paginationToSend: 10,
  positionToSend: undefined,
  activityWatermark: 0
}

const postActivityTypes = [
  LegacyDirectLineActivityType.SEND_MESSAGE,
  LegacyDirectLineActivityType.SEND_EVENT
]

export const useDirectLineStore = create<DirectLineStore>()(() => {
  return initialState
})

/**
 * Add a field in the 'from' part of the activity showing if it comes from the bot (backend) or the user
 * @param {Activity} activity incoming activity
 * @returns {RetorikActivity}
 */
const patchActivityFromPart = (activity: Activity): RetorikActivity => {
  const userId = useDirectLineStore.getState().userId
  const tempActivity = activity as RetorikActivity
  if (!activity.from) {
    tempActivity.from = { ...tempActivity.from, role: 'channel' }
  } else if (!activity.from.role) {
    if (activity.from.id === userId) {
      tempActivity.from = { ...tempActivity.from, role: 'user' }
    } else if (activity.from.id) {
      tempActivity.from = { ...tempActivity.from, role: 'bot' }
    } else {
      tempActivity.from = { ...tempActivity.from, role: 'channel' }
    }
  }

  return tempActivity
}

const checkIfHandlerAbortsActivity = (
  type: LegacyDirectLineActivityType,
  activity: Activity
): boolean => {
  const handler = useDirectLineStore.getState().externalActivityHandler

  if (handler) {
    const action = {
      type: type,
      payload: {
        activity: activity
      }
    }

    if (handler(action)) {
      setIsWaitingForResponse(false)
      unlockClickedButton()
      console.log('Retorik Framework > Aborted activity')
      return true
    }
  }

  return false
}

const dispatchStatus = (status: ConnectionStatus): void => {
  const handler = useDirectLineStore.getState().externalActivityHandler
  if (handler) {
    handler({
      type: LegacyDirectLineStatus[status]
    })
    status === ConnectionStatus.Online &&
      handler({
        type: LegacyDirectLineActivityType.SET_LANGUAGE
      })
  }
}

const dispatchActivity = (activity: Activity): void => {
  const retrievingConversation =
    useActivityStore.getState().retrievingConversation
  if (retrievingConversation) {
    // @ts-ignore
    const finalWatermark = useDirectLineStore.getState().directLine?.watermark
    if (!isNaN(parseInt(finalWatermark))) {
      const currentWatermark =
        useDirectLineStore.getState().activityWatermark + 1
      if (currentWatermark === parseInt(finalWatermark)) {
        setRetrievingConversation(false)
      } else {
        useDirectLineStore.setState({ activityWatermark: currentWatermark })
      }
    }
  }

  activity.conversation && checkConversationId(activity.conversation.id)
  // Patch 'from' part of the activity to distinct between 'bot', 'user and 'channel'
  const retorikActivity = useActivityMiddleware(patchActivityFromPart(activity))

  let legacyType: LegacyDirectLineActivityType =
    LegacyDirectLineActivityType.UNKNOWN
  switch (retorikActivity.type) {
    case 'message':
      legacyType =
        retorikActivity.from.role === 'user'
          ? LegacyDirectLineActivityType.SEND_MESSAGE
          : LegacyDirectLineActivityType.INCOMING_ACTIVITY
      break
    case 'event':
      legacyType =
        retorikActivity.from.role === 'user'
          ? LegacyDirectLineActivityType.SEND_EVENT
          : LegacyDirectLineActivityType.INCOMING_ACTIVITY
      break
    case 'typing':
      legacyType = LegacyDirectLineActivityType.TYPING
      break
  }

  // Let speechStore check if this activity has an impact on a curent streaming
  checkActivityReplyToId(retorikActivity.replyToId)

  switch (legacyType) {
    case LegacyDirectLineActivityType.SEND_EVENT:
      addUserEvent(retorikActivity)
      break
    case LegacyDirectLineActivityType.SEND_MESSAGE:
      addUserMessage(retorikActivity)
      break
    case LegacyDirectLineActivityType.INCOMING_ACTIVITY: {
      if (!checkIfHandlerAbortsActivity(legacyType, activity)) {
        // Add data for better speech recognition if therer are some
        if (retorikActivity.channelData?.speechRecognitionGrammars) {
          addSpeechRecognitionDynamicGrammar(
            retorikActivity.channelData?.speechRecognitionGrammars
          )
        }

        if (retorikActivity.type === 'event') {
          addBotEvent(retorikActivity)
        } else if (retorikActivity.type === 'message') {
          addBotMessage(retorikActivity)
        }
      }
      break
    }
  }

  // Send POST_ACTIVITY_FULFILLED if the legacy type is SEND_MESSAGE or SEND_EVENT
  if (postActivityTypes.includes(legacyType)) {
    checkIfHandlerAbortsActivity(
      LegacyDirectLineActivityType.POST_ACTIVITY_FULFILLED,
      activity
    )
  }
}

const subscribe = (value: DirectLine | null): void => {
  // Subscribe to connection status and activities if a value is provided
  const CSsubscription = value
    ? value.connectionStatus$.subscribe((newStatus) => {
        useDirectLineStore.setState({ directLineStatus: newStatus })
        dispatchStatus(newStatus)
      })
    : null
  const Asubscription = value
    ? value.activity$.subscribe((activity) => {
        activity.type !== 'typing' && dispatchActivity(activity)
      })
    : null

  useDirectLineStore.setState({
    directLine: value,
    connectionStatusSubscription: CSsubscription,
    activitySubscription: Asubscription
  })
}

const unsubscribe = (): void => {
  // Unsubscribe existing subscriptions to connection status and activities
  const connectionStatusSubscription =
    useDirectLineStore.getState().connectionStatusSubscription
  const activitySubscription =
    useDirectLineStore.getState().activitySubscription
  connectionStatusSubscription && connectionStatusSubscription.unsubscribe()
  activitySubscription && activitySubscription.unsubscribe()
}

const end = (): void => {
  const directLine = useDirectLineStore.getState().directLine
  directLine?.end()
}

export const createDirectLine = (data: DirectLineCreationData): void => {
  // Send DIRECT_LINE/CONNECT before creating directline because if everything goes well it only sends DIRECT_LINE/CONNECT_FULFILLED
  data.externalActivityHandler?.({
    type: LegacyDirectLineStatus[0]
  })
  const directLine = new DirectLine({
    webSocket: true,
    ...data
  })
  unsubscribe()
  subscribe(directLine)
  data.userId && useDirectLineStore.setState({ userId: data.userId })
  data.externalActivityHandler &&
    useDirectLineStore.setState({
      externalActivityHandler: data.externalActivityHandler
    })
}

/**
 * Add usefull data in channelData part, containing pagination and position / location
 * @returns {DataAddedToChannelData}
 */
const addDataInChannelData = (): DataAddedToChannelData => {
  const currentState = useDirectLineStore.getState()
  const dataAddedToChannelData: DataAddedToChannelData = {
    pageSize: currentState.paginationToSend || 10
  }

  if (
    currentState.positionToSend &&
    currentState.positionToSend.latitude &&
    currentState.positionToSend.longitude
  ) {
    dataAddedToChannelData.location = {
      latitude: currentState.positionToSend.latitude,
      longitude: currentState.positionToSend.longitude
    }
    dataAddedToChannelData.position = {
      latitude: currentState.positionToSend.latitude,
      longitude: currentState.positionToSend.longitude
    }
  }

  return dataAddedToChannelData
}

export const sendMessage = (
  text?: string,
  value?: Record<string, any>
): void => {
  setCancel(true)
  const locale = useLocaleStore.getState().locale
  const currentState = useDirectLineStore.getState()

  const tempActivity: Activity = {
    from: {
      id: currentState.userId
    },
    type: 'message',
    channelData: addDataInChannelData(),
    locale: locale
  }

  text && (tempActivity.text = text)
  value && (tempActivity.value = value)

  // Check if the activity should be stopped in the external activity handler if there is one
  if (
    checkIfHandlerAbortsActivity(
      LegacyDirectLineActivityType.POST_ACTIVITY,
      tempActivity
    )
  ) {
    return
  }
  if (
    checkIfHandlerAbortsActivity(
      LegacyDirectLineActivityType.SEND_MESSAGE,
      tempActivity
    )
  ) {
    return
  }

  currentState.directLine?.postActivity(tempActivity).subscribe()
}

export const sendEvent = (
  name: string,
  value?: Record<string, any> | null
): void => {
  !name.toLowerCase().includes('davi.closewindow') && setCancel(true)
  const locale = useLocaleStore.getState().locale
  const currentState = useDirectLineStore.getState()

  const tempEvent: Activity = {
    from: {
      id: currentState.userId
    },
    type: 'event',
    channelData: addDataInChannelData(),
    // @ts-ignore
    locale: locale,
    name: name,
    value: value
  }

  // Check if the event should be stopped in the external activity handler if there is one
  if (
    checkIfHandlerAbortsActivity(
      LegacyDirectLineActivityType.POST_ACTIVITY,
      tempEvent
    )
  ) {
    return
  }
  if (
    checkIfHandlerAbortsActivity(
      LegacyDirectLineActivityType.SEND_EVENT,
      tempEvent
    )
  ) {
    return
  }

  currentState.directLine
    ?.postActivity({
      from: {
        id: currentState.userId
      },
      type: 'event',
      channelData: addDataInChannelData(),
      // @ts-ignore
      locale: locale,
      name: name,
      value: value
    })
    .subscribe()
}

export const sendTyping = (): void => {
  const currentState = useDirectLineStore.getState()
  currentState.directLine
    ?.postActivity({
      from: {
        id: currentState.userId
      },
      type: 'typing'
    })
    .subscribe()
}

export const setPaginationToSend = (value: number): void => {
  useDirectLineStore.setState({ paginationToSend: value })
}

export const setPositionToSend = (
  value: { latitude: number; longitude: number } | undefined
): void => {
  useDirectLineStore.setState({ positionToSend: value })
}

export const dispatchMarkActivity = (id: string, speaking: boolean): void => {
  const handler = useDirectLineStore.getState().externalActivityHandler

  if (handler) {
    const action = {
      type: LegacyDirectLineActivityType.MARK,
      payload: {
        activityID: id,
        name: 'speak',
        value: speaking
      }
    }

    handler(action)
  }
}

export const resetDirectlineStore = (): void => {
  unsubscribe()
  end()
  useDirectLineStore.setState({ ...initialState })
}
