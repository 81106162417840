/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useViewStore } from '../Contexts/viewStore'

import { Mode, DeviceType } from '../../models/enums'

import VoiceInput from './VoiceInput'
import SendTextBox from '../Utils/SendTextBox'

const UserRequest = (): JSX.Element => {
  const configuration = useRetorikStore((state) => state.configuration)
  const mode = useRetorikStore((state) => state.mode)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const mediumLandscape = useViewStore((state) => state.mediumLandscape)
  const isBorne = useViewStore((state) => state.isBorne)

  const [gridRowStart, setGridRowStart] = useState<number>()
  const [gridRowSpan, setGridRowSpan] = useState<number>()

  useEffect(() => {
    switch (currentDeviceType) {
      case DeviceType.landscape:
      case DeviceType.widgetLandscape: {
        if (
          typeof configuration?.answerpanel === 'object' &&
          configuration.answerpanel.landscape
        ) {
          if (configuration.answerpanel.landscape.containerRowStart) {
            setGridRowStart(1)
            setGridRowSpan(
              configuration.answerpanel.landscape.containerRowStart - 1
            )
          } else {
            setGridRowStart(1)
            setGridRowSpan(mode === Mode.text ? 3 : 4)
          }
        } else {
          setGridRowStart(1)
          setGridRowSpan(mode === Mode.text ? 3 : 4)
        }
        break
      }
      case DeviceType.borne:
      case DeviceType.widgetBorne: {
        if (
          typeof configuration?.answerpanel === 'object' &&
          configuration.answerpanel.borne
        ) {
          if (configuration.answerpanel.borne.containerRowStart) {
            setGridRowStart(1)
            setGridRowSpan(
              configuration.answerpanel.borne.containerRowStart - 1
            )
          } else {
            setGridRowStart(5)
            setGridRowSpan(2)
          }
        } else {
          setGridRowStart(5)
          setGridRowSpan(2)
        }
        break
      }
    }
  }, [mode, configuration.answerpanel, currentDeviceType])

  return (
    <div
      className={`rf-relative rf-h-full rf-py-4 large-vertical:rf-py-5 rf-flex rf-flex-col rf-justify-self-center rf-justify-end rf-text-size-auto rf-text-trueblack
        ${isBorne && mode === Mode.vocal ? 'rf-w-4/9' : 'rf-w-3/5'}
        ${
          mode === Mode.text && mediumLandscape
            ? 'rf-col-start-4'
            : 'rf-col-start-5'
        } large-vertical:rf-col-start-1 rf-col-span-full`}
      style={{
        gridRow: `${gridRowStart} / span ${gridRowSpan}`
      }}
      id='retorik-userRequest'
    >
      {mode === Mode.text ? <SendTextBox withButton={true} /> : <VoiceInput />}
    </div>
  )
}

export default UserRequest
