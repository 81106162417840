import React, { useLayoutEffect, useRef, useState } from 'react'
import type { DirectLineAttachment } from '../../../models/directLine'
import renderAttachment from '../../Middlewares/attachmentMiddleware'
import Carousel from '../../AnswerPanel/Carousel'
import Card from '../../AnswerPanel/Card'

interface CarouselContainerProps {
  title?: string
  attachments: Array<DirectLineAttachment>
  history?: boolean
  grid?: boolean
}

const CarouselContainer = ({
  title,
  attachments,
  history,
  grid
}: CarouselContainerProps): JSX.Element => {
  const [height, setHeight] = useState<number>(0)
  const timerRef = useRef<NodeJS.Timeout>()
  const carouselRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null)

  useLayoutEffect(() => {
    setHeight(0)
    if (attachments.length && attachments?.length > 1) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        carouselRef.current && setHeight(carouselRef.current.clientHeight)
      }, 500)
    }

    return () => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [attachments])

  return (
    <Carousel
      ref={carouselRef}
      history={history}
      draft={height === 0}
      grid={grid}
      gridTitle={title}
    >
      {height === 0
        ? attachments.map((attachment, key) => {
            return (
              <Card key={key + 100} className='rf-w-full'>
                {renderAttachment({
                  attachment: attachment,
                  history: history
                })}
              </Card>
            )
          })
        : attachments.map((attachment, key) => {
            return (
              <Card key={key} className='rf-w-full'>
                {renderAttachment({
                  attachment: attachment,
                  history: history,
                  height: height
                })}
              </Card>
            )
          })}
    </Carousel>
  )
}

export default CarouselContainer
