/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { useViewStore } from '../../Contexts/viewStore'
import useActivitiesTextMode from '../../../hooks/useActivitiesTextMode'
import type { RetorikActivity } from '../../../models/activityTypes'
import { DeviceType } from '../../../models/enums'
import Attachments from '../../Attachments/Attachments'
import ConversationDisplay from './ConversationDisplay'

interface ConversationPanelLargeProps {
  homeActivity: RetorikActivity | undefined
  itemsInConversation: number
}

const ConversationPanelLarge = ({
  homeActivity,
  itemsInConversation
}: ConversationPanelLargeProps): JSX.Element => {
  const activities = useActivitiesTextMode()
  const configuration = useRetorikStore((state) => state.configuration)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const mediumLandscape = useViewStore((state) => state.mediumLandscape)

  const botEndRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const [gridRowStart, setGridRowStart] = useState<number>()
  const [gridRowSpan, setGridRowSpan] = useState<number>()

  useEffect(() => {
    switch (currentDeviceType) {
      case DeviceType.landscape:
      case DeviceType.widgetLandscape: {
        if (
          typeof configuration?.answerpanel === 'object' &&
          configuration.answerpanel.landscape
        ) {
          const start = configuration.answerpanel.landscape.containerRowStart
          const end = configuration.answerpanel.landscape.containerRowEnd

          if (start && start > 0 && start < 13) {
            if (end && end < 13) {
              if (start < end) {
                setGridRowStart(start)
                setGridRowSpan(end - start)
              }
            } else {
              setGridRowStart(start)
              setGridRowSpan(13 - start)
            }
          } else if (end && end > 0 && end < 13) {
            setGridRowStart(1)
            setGridRowSpan(end - 1)
          } else {
            setGridRowStart(4)
            setGridRowSpan(8)
          }
        } else {
          setGridRowStart(4)
          setGridRowSpan(8)
        }
        break
      }
      case DeviceType.borne:
      case DeviceType.widgetBorne: {
        if (
          typeof configuration.answerpanel === 'object' &&
          configuration.answerpanel.borne
        ) {
          const start = configuration.answerpanel.borne.containerRowStart
          const end = configuration.answerpanel.borne.containerRowEnd

          if (start && start > 0 && start < 13) {
            if (end && end < 13) {
              if (start < end) {
                setGridRowStart(start)
                setGridRowSpan(end - start)
              }
            } else {
              setGridRowStart(start)
              setGridRowSpan(13 - start)
            }
          } else if (end && end > 0 && end < 13) {
            setGridRowStart(1)
            setGridRowSpan(end - 1)
          } else {
            setGridRowStart(7)
            setGridRowSpan(6)
          }
        } else {
          setGridRowStart(7)
          setGridRowSpan(6)
        }
        break
      }
    }
  }, [currentDeviceType, configuration.answerpanel])

  const userQuestion = useMemo<RetorikActivity | undefined>(() => {
    return activities.length && activities[activities.length - 1].question?.text
      ? activities[activities.length - 1].question
      : undefined
  }, [activities])

  const botResponses = useMemo<Array<RetorikActivity> | undefined>(() => {
    return activities.length
      ? activities[activities.length - 1].answers
      : undefined
  }, [activities])

  const handleScroll = (): void => {
    botEndRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      handleScroll()
    }, 100)

    return (): void => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [itemsInConversation])

  return homeActivity?.attachments?.length || userQuestion || botResponses ? (
    <div
      className={`rf-relative rf-max-h-full ${
        mediumLandscape ? 'rf-col-start-4' : 'rf-col-start-5'
      } vertical:rf-col-start-1 rf-col-span-full rf-row-start-4 rf-row-end-12 large-vertical:rf-row-start-7 large-vertical:rf-row-span-6 rf-self-start rf-overflow-hidden rf-justify-self-center rf-w-3/5 rf-flex rf-flex-col rf-rounded rf-bg-textModePanelBackground`}
      style={{
        gridRow: `${gridRowStart} / span ${gridRowSpan}`
      }}
    >
      <div
        id='retorik-conversation'
        className='rf-relative rf-max-h-full rf-bg-textModePanelBackground rf-py-4 rf-overflow-y-scroll rf-overflow-x-hidden rf-scrollbar-thin vertical:rf-overflow-x-auto rf-justify-self-center rf-flex rf-flex-col rf-text-size-auto rf-text-trueblack rf-shadow-[3px_3px_6px_#00000029]'
      >
        {/* Home activity attachments or only the last question / answers */}
        {homeActivity?.attachments?.length ? (
          <div className='rf-pt-4 rf-flex rf-flex-col rf-text-size-auto rf-gap-2'>
            <Attachments activity={homeActivity} />
          </div>
        ) : (
          <ConversationDisplay
            userQuestion={userQuestion}
            botResponses={botResponses}
            triggerScroll={handleScroll}
            canBeStreaming={true}
          />
        )}
        <div ref={botEndRef} />
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default ConversationPanelLarge
