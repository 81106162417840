{
  "1": "홈페이지",
  "2": "라이브 뉴스 채널",
  "3": "일기 예보",
  "4": "긴급 전화번호",
  "1000": "역사적인",
  "1001": "지도 시간",

  "common": {
    "all": "모두",
    "back": "뒤쪽에",
    "close": "닫다",
    "continue": "계속하다",
    "download": "다운로드",
    "downloadsheet": "이 시트 다운로드",
    "downloadsheetmobile": "이 시트를 스마트폰에 다운로드하세요",
    "filter": "필터",
    "history": "역사적인",
    "lang": "언어",
    "menu": "메뉴",
    "mode": "패션",
    "needhelp": "도움이 필요하다?",
    "parameters": "설정",
    "print": "인쇄",
    "printsheet": "이 시트를 인쇄하세요",
    "result": "결과",
    "results": "결과",
    "singleLang": "언어",
    "sound": "그녀의",
    "submit": "제출하다",
    "subtitle": "부제",
    "tutorial": "지도 시간",
    "validate": "확인"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "경찰",
    "firefighter": "소방관",
    "general": "일반적인",
    "hospital": "병원",
    "homeless": "노숙자",
    "poison": "독극물 관리 센터",
    "emergency": "긴급 상황"
  },

  "error": {
    "title": "오류가 발생했습니다",
    "button": "애플리케이션을 다시 시작하세요."
  },

  "history": {
    "title": "대화 기록"
  },

  "inputs": {
    "choose": "답변을 선택하세요"
  },

  "language": {
    "change": "언어를 바꿔라",
    "select": "사용하고 싶은 언어를 선택하세요"
  },

  "link": {
    "buttontext": "문서 보기",
    "goto": "주소로 이동",
    "click": "여기를 클릭하세요",
    "scanQR": "QrCode를 스캔하여",
    "scanQRTile": "QR 코드를 스캔하여 발견하다"
  },

  "loader": {
    "loader": {
      "optimal": "최적의 경험을 위해,",
      "classic": "좀 더 클래식한 경험을 원하신다면,",
      "vocal": "저는 상담원과 직접 대면하여 이야기합니다.",
      "text": "나는 대리인과 서면으로 의사소통합니다."
    },
    "modes": {
      "vocal": "보컬 모드",
      "text": "텍스트 모드"
    },
    "loaderValidation": "대화 시작",
    "locale": "언어 확인",
    "supported": "지원되는 언어 확인",
    "addressdata": "데이터 검색"
  },

  "menu": {
    "message": "메시지를 입력하세요",
    "allowMicrophone": "계속하려면 브라우저에서 마이크를 사용하도록 허용하세요.",
    "backconversation": "대화로 돌아가서",
    "answer": " 귀하의 질문에 답변"
  },

  "mobileLandscapePreventer": {
    "firstline": "최적의 경험을 위해,",
    "secondline": "화면을 뒤집어"
  },

  "news": {
    "warningtext": "뉴스 채널에 접속하게 되며 기기의 소리가 활성화됩니다.",
    "live": "살다",
    "continuous": "계속해서"
  },

  "poi": {
    "comment": "고객 리뷰",
    "route": "도로",
    "nodata": "검색어와 일치하는 콘텐츠가 없습니다.",
    "openinghours": "개관 시간",
    "prices": "물가",
    "paymentmethods": "결제 방법",
    "numberofplaces": "장소의 수",
    "openroutemobile": "스마트폰에서 Google 지도 경로 열기",
    "FoodEstablishment": "먹을 곳",
    "LodgingBusiness": "잠을 잘 곳",
    "TouristDestination": "무엇을 볼 것인가",
    "TouristAttraction": "해야 할 일",
    "Product": "지역상품",
    "Service": "서비스",
    "Event": "이벤트",
    "City": "도시",
    "Wine": "와인"
  },

  "suggestion": {
    "consult": "확인해 보세요",
    "thetutorial": "튜토리얼"
  },

  "template": {
    "reset": "모두 초기화",
    "showresults": "결과 표시",
    "suggestiontitle": "검색 제안",
    "reminder": "잊지 마세요. \n 제가 귀하의 질문에 답변해드릴 수 있습니다."
  }
}
