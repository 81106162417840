import { create } from 'zustand'
import { unlockClickedButton, setListClosed } from './utilsStore'
import { addToStreamingQueue, setStreamingQueueFullLength } from './speechStore'
import { setIsWaitingForResponse, useRetorikStore } from './retorikStore'
import { setCurrentId } from './adaptiveCardStore'
import { setShowHomeAttachments } from './viewStore'

import type {
  Action,
  SuggestedActionAttachment
} from '../../models/suggestedActionAttachment'
import { RetorikActivity } from '../../models/activityTypes'
import {
  CONTENT_TYPE_LINK,
  CONTENT_TYPE_SUGGESTEDACTION,
  CONTENT_TYPE_SUGGESTION
} from '../../models/attachmentTypes'
import { nonResponseInducingEvents } from '../../models/constants'
import { processMarkdownAndSpeechMarkdown } from '../../utils/markdownUtils'
import { processAttachments } from '../../utils/processAttachments'
import createLinkAttachmentCard, {
  createAttachmentsFromMultiLink
} from '../../utils/createLinkAttachmentCard'
import createImageAttachmentCard from '../../utils/createImageAttachmentCard'
import processDirectLineIncomingActivity from '../../utils/processDirectlineIncomingActivity'

interface ActivityStore {
  // All activities
  activities: Array<RetorikActivity>
  // (all / last) message / events activities
  lastActivity: RetorikActivity | undefined
  messageActivities: Array<RetorikActivity>
  lastMessageActivity: RetorikActivity | undefined
  eventActivities: Array<RetorikActivity>
  lastEventActivity: RetorikActivity | undefined
  // Bot activities
  lastBotActivity: RetorikActivity | undefined
  botMessageActivities: Array<RetorikActivity>
  lastBotMessageActivity: RetorikActivity | undefined
  botEventActivities: Array<RetorikActivity>
  lastBotEventActivity: RetorikActivity | undefined
  // User activities
  userMessageActivities: Array<RetorikActivity>
  lastUserMessageActivity: RetorikActivity | undefined
  // Miscellaneous
  watermark: number
  conversationId: string | null
  homeActivity: RetorikActivity | undefined
  retrievingConversation: boolean
  addRetorikNewsConversationIdInLocalStorage: string | undefined
  // Cookie utils
  cookies: Array<string>
  // Queue
  queue: Array<RetorikActivity>
  queueLock: boolean
}

const initialState: ActivityStore = {
  activities: [],
  lastActivity: undefined,
  lastBotActivity: undefined,
  homeActivity: undefined,
  messageActivities: [],
  lastMessageActivity: undefined,
  eventActivities: [],
  lastEventActivity: undefined,
  botMessageActivities: [],
  lastBotMessageActivity: undefined,
  botEventActivities: [],
  lastBotEventActivity: undefined,
  userMessageActivities: [],
  lastUserMessageActivity: undefined,
  conversationId: null,
  watermark: 0,
  retrievingConversation: false,
  addRetorikNewsConversationIdInLocalStorage: undefined,
  cookies: [],
  queue: [],
  queueLock: false
}

const regexDataCommand =
  /(\{data-command=")[\p{L}\p{N}\p{Po}\p{Pd}\p{Pc}\s\u0027\u2019]+("\})(<br\s\/>)?/gu

export const useActivityStore = create<ActivityStore>()(() => {
  return initialState
})

const checkCurrentConversationId = (value: string | undefined): boolean => {
  const currentConversationId = useActivityStore.getState().conversationId
  return !!(currentConversationId && value?.includes(currentConversationId))
}

export const checkConversationId = (value: string): void => {
  const currentState = useActivityStore.getState()

  // Add current conversation id with tenant name in local storage if we are in a RetorikNews component.
  // This prevents creating a new conversation each time RetorikNews is launched (used in Retorik Kiosk).
  if (currentState.addRetorikNewsConversationIdInLocalStorage) {
    const dataToPutInlocalStorage = {
      conversationId: value,
      startedAt: Date.now()
    }

    localStorage.setItem(
      `Retorik.News.Conversation.${currentState.addRetorikNewsConversationIdInLocalStorage}`,
      JSON.stringify(dataToPutInlocalStorage)
    )

    useActivityStore.setState({
      addRetorikNewsConversationIdInLocalStorage: undefined
    })
  }

  // Check directline id to know if we have to reset the activities
  if (value && value !== currentState.conversationId) {
    currentState.conversationId === null
      ? useActivityStore.setState({ conversationId: value })
      : useActivityStore.setState({ ...initialState, conversationId: value })

    // Set desired cookies once the conversation id is set / changed
    setCookiesInBrowser()
  }
}

const processQueue = async (): Promise<void> => {
  const currentQueue = useActivityStore.getState().queue
  if (currentQueue.length) {
    const currentActivity = currentQueue[0]
    currentActivity.type === 'message'
      ? await processAddBotMessage(currentActivity)
      : await processAddBotEvent(currentActivity)
    // Remove the first element from the queue (the one that got processed above) and process the queue once again
    useActivityStore.setState({ queue: currentQueue.splice(1) })
    processQueue()
  } else {
    useActivityStore.setState({ queueLock: false })
  }
}

const processAddBotMessage = async (value: RetorikActivity): Promise<void> => {
  setCurrentId(undefined)
  const currentState = useActivityStore.getState()
  const processedActivity = await processBotMessageActivity(value)
  // Set the home activity to be able to show its attachments when needed
  if (processedActivity.messageType === 'welcome') {
    useActivityStore.setState({ homeActivity: processedActivity })
  }

  // If this activity is the one concluding a streaming, let's set the amount of streams previously generated
  if (!currentState.retrievingConversation && value.value?.streamingCount) {
    setStreamingQueueFullLength(value.value.streamingCount, value.replyToId)
  }

  const tempActivity = currentState.retrievingConversation
    ? undefined
    : processedActivity

  useActivityStore.setState({
    activities: [...currentState.activities, processedActivity],
    lastActivity: tempActivity,
    lastBotActivity: tempActivity,
    messageActivities: [...currentState.messageActivities, processedActivity],
    botMessageActivities: [
      ...currentState.botMessageActivities,
      processedActivity
    ],
    lastMessageActivity: tempActivity,
    lastBotMessageActivity: tempActivity,
    watermark: currentState.watermark + 1
  })

  Promise.resolve()
}

export const addBotMessage = async (value: RetorikActivity): Promise<void> => {
  const currentState = useActivityStore.getState()
  if (checkCurrentConversationId(value.id)) {
    if (currentState.queueLock) {
      useActivityStore.setState({ queue: [...currentState.queue, value] })
    } else {
      useActivityStore.setState({ queueLock: true })
      await processAddBotMessage(value)
      setShowHomeAttachments(undefined)
      setListClosed(false)
      unlockClickedButton()
      setIsWaitingForResponse(false)
      processQueue()
    }
  }
}

const processAddBotEvent = async (value: RetorikActivity): Promise<void> => {
  const currentState = useActivityStore.getState()
  useActivityStore.setState({
    activities: [...currentState.activities, value],
    lastActivity: currentState.retrievingConversation ? undefined : value,
    lastBotActivity: currentState.retrievingConversation ? undefined : value,
    eventActivities: [...currentState.eventActivities, value],
    botEventActivities: [...currentState.botEventActivities, value],
    lastBotEventActivity: value,
    lastEventActivity: value,
    watermark: currentState.watermark + 1
  })

  Promise.resolve()
}

export const addBotEvent = async (value: RetorikActivity): Promise<void> => {
  if (checkCurrentConversationId(value.id)) {
    const currentState = useActivityStore.getState()

    if (value.name?.toLowerCase() === 'davi.streammessageactivity') {
      if (value.text?.trim().length && !currentState.retrievingConversation) {
        setIsWaitingForResponse(false)

        // Create speak field from the text one
        const processedText = processMarkdownAndSpeechMarkdown(value.text, true)
        value.speak = processedText.text
        setShowHomeAttachments(undefined)
        addToStreamingQueue(value)
        unlockClickedButton()
      }
    } else {
      if (currentState.queueLock) {
        useActivityStore.setState({ queue: [...currentState.queue, value] })
      } else {
        useActivityStore.setState({ queueLock: true })
        await processAddBotEvent(value)
        unlockClickedButton()
        setIsWaitingForResponse(false)
        processQueue()
      }
    }
  }
}

export const addUserMessage = async (value: RetorikActivity): Promise<void> => {
  if (checkCurrentConversationId(value.id)) {
    setIsWaitingForResponse(true)

    const currentState = useActivityStore.getState()
    useActivityStore.setState({
      activities: [...currentState.activities, value],
      lastActivity: value,
      messageActivities: [...currentState.messageActivities, value],
      userMessageActivities: [...currentState.userMessageActivities, value],
      lastMessageActivity: value,
      lastUserMessageActivity: value,
      watermark: currentState.watermark + 1
    })
  }
}

export const addUserEvent = (value: RetorikActivity): void => {
  if (checkCurrentConversationId(value.id)) {
    // Only wait for response if the event is one that need it
    !nonResponseInducingEvents.find((eventName) =>
      value.name?.toLowerCase().includes(eventName)
    ) && setIsWaitingForResponse(true)

    const currentState = useActivityStore.getState()
    useActivityStore.setState({
      activities: [...currentState.activities, value],
      lastActivity: value,
      lastEventActivity: value,
      eventActivities: [...currentState.eventActivities, value],
      watermark: currentState.watermark + 1
    })
  }
}

export const setRetrievingConversation = (value: boolean): void => {
  useActivityStore.setState({ retrievingConversation: value })
}

export const setAddRetorikNewsConversationIdInLocalStorage = (
  value: string | undefined
): void => {
  useActivityStore.setState({
    addRetorikNewsConversationIdInLocalStorage: value
  })
}

export const addCookie = (value: string | null): void => {
  if (value) {
    const currentCookies = useActivityStore.getState().cookies
    useActivityStore.setState({ cookies: [...currentCookies, value] })
  }
}

export const setCookiesInBrowser = (): void => {
  const cookies = useActivityStore.getState().cookies
  if (cookies.length) {
    cookies.forEach((cookie) => {
      if (cookie.indexOf('<conversationId>') > -1) {
        const conversationId = useActivityStore.getState().conversationId

        if (conversationId) {
          const processedCookie = cookie.replace(
            '<conversationId>',
            conversationId
          )
          document.cookie = processedCookie
        }
      } else {
        document.cookie = cookie
      }
    })

    useActivityStore.setState({ cookies: [] })
  }
}

export const resetActivityStore = (): void => {
  useActivityStore.setState({ ...initialState })
}

const processBotMessageActivity = async (
  activity: RetorikActivity
): Promise<RetorikActivity> => {
  const isUsedOnBorne = useRetorikStore.getState().configuration.isUsedOnBorne
  // If the application is used on a borne and there are attachments, check if a received attachment is a multi-link one.
  // If there is some, we need to split it in several attachments, one for each link.
  if (isUsedOnBorne && activity.attachments?.length) {
    let count = 0
    const tempAttachments: Array<any> = []
    for (const attachment of activity.attachments) {
      if (
        attachment.contentType === CONTENT_TYPE_LINK &&
        Array.isArray(attachment.content?.urlData)
      ) {
        count++
        const splitAttachments = await createAttachmentsFromMultiLink(
          attachment.content.urlData
        )
        tempAttachments.push(...splitAttachments)
      } else {
        tempAttachments.push(attachment)
      }
    }
    // If some attachments have been split, we replace the attachments in the activity
    count > 0 && (activity.attachments = tempAttachments)
    // Carousel display if there is more than 1 card
    count > 0 &&
      activity.attachments.length > 1 &&
      (activity.attachmentLayout = 'carousel')
  }

  const processedData = processDirectLineIncomingActivity(activity)
  // Set processed speak, text and attachments if there are some
  processedData.speak && (activity.speak = processedData.speak)
  processedData.text && (activity.text = processedData.text)
  processedData.htmlText && (activity.htmlText = processedData.htmlText)

  // Check if there are cards to create from images
  if (processedData.images.length > 0) {
    // If there is no attachment field, create an empty one
    activity.attachments === undefined && (activity.attachments = [])
    // Create content from image data and add it to the attachments
    for (const image of processedData.images) {
      const imageAttachment = await createImageAttachmentCard(image)
      activity.attachments.push(imageAttachment)
    }
    activity.attachments.length > 1 && (activity.attachmentLayout = 'carousel')
  }

  // Check if there are cards to create from links
  if (processedData.urls.length > 0) {
    // If there is no attachment field, create an empty one
    activity.attachments === undefined && (activity.attachments = [])
    // Create content asynchonously from url data and add it to the attachments
    for (const url of processedData.urls) {
      const fetchedAttachment = await createLinkAttachmentCard(url)
      activity.attachments.push(fetchedAttachment)
    }
    activity.attachments.length > 1 && (activity.attachmentLayout = 'carousel')
  }

  // Transform herocards in adaptivecards
  const heroToAdaptiveAttachments = processAttachments(activity.attachments)
  heroToAdaptiveAttachments &&
    (activity.attachments = [...heroToAdaptiveAttachments])

  // Transform suggested actions into cards if the display isn't daviList
  if (
    activity.suggestedActions?.actions &&
    activity.attachmentLayout?.toLowerCase() !== 'davilist'
  ) {
    let actionsArray: Array<Action> = []
    activity.suggestedActions.actions.forEach((suggestedAction) => {
      const action: Action = {
        title: suggestedAction.title,
        action: suggestedAction.value
      }

      actionsArray = [...actionsArray, action]
    })

    const suggestedActionAttachment: SuggestedActionAttachment = {
      contentType: CONTENT_TYPE_SUGGESTEDACTION,
      content: {
        title: activity.text || '',
        actions: actionsArray
      }
    }

    // Create field attachments with the newly created one if no attachment yet, or add to existing ones
    activity.attachments === undefined
      ? (activity.attachments = [suggestedActionAttachment])
      : activity.attachments.push(suggestedActionAttachment)
  }

  // Deal with text containing [data command] data to make them usable in text mode
  if (activity?.text && activity.text.includes('{data-command=')) {
    const splitText: Array<string> = activity.text.split('{data-command=')
    const dataForAttachment: Array<{
      text: string
      differentTextToSend: string
    }> = []
    for (let i = 1; i < splitText.length; i++) {
      const text = splitText[i - 1]
        .substring(
          splitText[i - 1].lastIndexOf('\n')
            ? splitText[i - 1].lastIndexOf('\n') + 1
            : 0
        )
        .trim()
      const replacement = splitText[i]
        .substring(0, splitText[i].indexOf('}'))
        .replaceAll('"', '')

      dataForAttachment.push({
        text: text,
        differentTextToSend: replacement
      })

      // Remove text part from speak / text / htmlText
      activity.speak && (activity.speak = activity.speak.replace(text, ''))
      activity.text = activity.text.replace(text, '')
      activity.htmlText = activity.htmlText?.replace(text, '')
    }

    if (dataForAttachment.length > 0) {
      const suggestionAttachment = {
        contentType: CONTENT_TYPE_SUGGESTION,
        content: {
          suggestions: dataForAttachment,
          showTutorial: false
        }
      }

      // Create field attachment swith the newly created one if no attachment yet, or add to existing ones
      activity.attachments === undefined
        ? (activity.attachments = [suggestionAttachment])
        : activity.attachments.push(suggestionAttachment)
    }

    // Remove all [data command] from speak / text / htmlText
    activity.speak &&
      (activity.speak = activity.speak.replaceAll(regexDataCommand, ''))
    activity.text = activity.text.replaceAll(regexDataCommand, '')
    activity.htmlText = activity.htmlText?.replaceAll(regexDataCommand, '')
  }

  return activity
}
