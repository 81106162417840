/* eslint-disable react/jsx-boolean-value */
import React, { useMemo } from 'react'
import { useViewStore } from '../Contexts/viewStore'

import type { DirectLineAttachment } from '../../models/directLine'
import type { RetorikActivity } from '../../models/activityTypes'
import { CONTENT_TYPE_GRID_TITLE } from '../../models/attachmentTypes'
import { Displays } from '../../models/enums'

import SingleCardContainer from './Attachments/SingleCardContainer'
import CarouselContainer from './Attachments/CarouselContainer'
import GridContainer from './Attachments/GridContainer'

interface AttachmentsProps {
  activity: RetorikActivity
  display?: Displays
  history?: boolean
}

const Attachments = ({
  activity,
  display,
  history
}: AttachmentsProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)

  const attachments = useMemo<Array<DirectLineAttachment>>(() => {
    return (
      activity?.attachments?.filter(
        (attachment) => attachment.contentType !== CONTENT_TYPE_GRID_TITLE
      ) || []
    )
  }, [activity])

  const checkDisplay = (attachmentLayout: string | undefined): Displays => {
    if (!attachments?.length) {
      return Displays.none
    }

    if (attachmentLayout) {
      switch (attachmentLayout.toLowerCase()) {
        case 'davilist':
          return Displays.none
        case 'carousel':
          return attachments.length > 1 ? Displays.carousel : Displays.normal
        case 'grid':
          // Grid display is not available in mobile mode
          return isMobile
            ? attachments.length > 1
              ? Displays.carouselgrid
              : Displays.normal
            : Displays.grid
      }
    }

    // If we have no attachment layout but attachment(s), we use none display by default is there are several attachments
    return attachments.length > 1 ? Displays.none : Displays.normal
  }

  const currentDisplay = useMemo<Displays>(() => {
    return display || checkDisplay(activity.attachmentLayout)
  }, [attachments, display])

  return currentDisplay === Displays.normal ? (
    <SingleCardContainer attachment={attachments[0]} history={history} />
  ) : currentDisplay === Displays.carousel ||
    currentDisplay === Displays.carouselgrid ? (
    <CarouselContainer
      title={
        activity.attachments?.find(
          (attachment) => attachment.contentType === CONTENT_TYPE_GRID_TITLE
        )?.content?.text
      }
      attachments={attachments}
      history={history}
      grid={currentDisplay === Displays.carouselgrid}
    />
  ) : currentDisplay === Displays.grid ? (
    <GridContainer
      title={
        activity.attachments?.find(
          (attachment) => attachment.contentType === CONTENT_TYPE_GRID_TITLE
        )?.content?.text
      }
      attachments={attachments}
      history={history}
    />
  ) : (
    <React.Fragment />
  )
}

export default Attachments
