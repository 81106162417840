{
  "1": "Startseite",
  "2": "Live-Infokanal",
  "3": "Wetter",
  "4": "Notrufnummern",
  "1000": "Geschichte",
  "1001": "anleitung",

  "common": {
    "all": "alle",
    "back": "zurück",
    "close": "schließen",
    "continue": "Weiter",
    "download": "herunterladen",
    "downloadsheet": "Dieses Blatt herunterladen",
    "downloadsheetmobile": "Laden Sie dieses Blatt auf Ihr Smartphone herunter",
    "filter": "filter",
    "history": "Verlauf",
    "lang": "Sprachen",
    "menu": "Menü",
    "mode": "Modus",
    "needhelp": "Brauche Hilfe ?",
    "parameters": "Parameter",
    "print": "Ausdrucken",
    "printsheet": "Blat ausdrucken",
    "result": "Ergebnis",
    "results": "Ergebnisse",
    "singleLang": "Sprache",
    "sound": "Ton",
    "subtitle": "Untertitel",
    "submit": "einreichen",
    "tutorial": "Lernprogramm",
    "validate": "validieren"
  },

  "emergency": {
    "samu": "samu",
    "police": "polizei",
    "firefighter": "feuerwehrleute",
    "general": "allgemein",
    "citypolice": "Lokale Polizeibehörde",
    "hospital": "Krankenhaus",
    "homeless": "Obdachlos (kostenloser Anruf)",
    "poison": "Giftkontrollzentrum",
    "emergency": "Europäische Notfälle"
  },

  "error": {
    "title": "Es ist ein Fehler aufgetreten",
    "button": "Starten Sie die Anwendung erneut"
  },

  "history": {
    "title": "Chat-Verlauf"
  },

  "inputs": {
    "choose": "Wählen Sie eine Antwort"
  },

  "language": {
    "change": "die Sprache ändern",
    "select": "Wählen Sie die gewünschte Sprache"
  },

  "link": {
    "buttontext": "Sehen Sie sich das Dokument an",
    "goto": "Zur Adresse gehen",
    "click": "klicken Sie hier",
    "scanQR": "durch Scannen des QrCodes",
    "scanQRTile": "Scannen Sie den QR-Code, um zu entdecken"
  },

  "loader": {
    "loader": {
      "optimal": "Für ein optimales Erlebnis,",
      "classic": "Für ein eher klassisches Erlebnis,",
      "vocal": "Ich kommuniziere durch Sprechen mit dem Agenten.",
      "text": "Ich kommuniziere per Text mit dem Agenten."
    },
    "modes": {
      "vocal": "Vokal-Modus",
      "text": "Textmodus"
    },
    "loaderValidation": "Gespräch beginnen",
    "locale": "Gebietsschema prüfen",
    "supported": "Prüfung der unterstützten Gebietsschemata",
    "store": "Speicher erstellen",
    "directline": "Direktleitung verbinden",
    "ponyfill": "ponyfilling",
    "addressdata": "Abrufen von Daten"
  },

  "menu": {
    "message": "Geben Sie Ihre Nachricht ein",
    "allowMicrophone": "Geben Sie Ihrem Browser die Erlaubnis, das Mikrofon zu benutzen, um fortzufahren",
    "backconversation": "zurück zum Gespräch",
    "answer": " beantwortet Ihre Fragen"
  },

  "mobileLandscapePreventer": {
    "firstline": "Für ein optimales Erlebnis,",
    "secondline": "drehen Sie Ihren Bildschirm um"
  },

  "news": {
    "warningtext": "Sie sind dabei, den Informationskanal aufzurufen, der Ton Ihres Geräts wird aktiviert",
    "live": "live",
    "continuous": "fortlaufend"
  },

  "poi": {
    "comment": "Kundenbewertung",
    "route": "Route",
    "nodata": "Kein Inhalt entspricht Ihrer Suche",
    "openinghours": "Öffnungszeiten",
    "prices": "Preise",
    "paymentmethods": "Zahlungsarten",
    "numberofplaces": "Anzahl der Plätze",
    "openroutemobile": "Öffnen Sie die Google Map-Route auf dem Smartphone",
    "FoodEstablishment": "Wo sollen wir essen",
    "LodgingBusiness": "Wo schlafen",
    "TouristDestination": "Was zu sehen",
    "TouristAttraction": "Was zu tun",
    "Product": "Lokale Produkte",
    "Service": "Dienstleistungen",
    "Event": "Veranstaltungen",
    "City": "Städte",
    "Wine": "Weine"
  },

  "suggestion": {
    "consult": "Schauen Sie sich",
    "thetutorial": "das Tutorial an"
  },

  "template": {
    "reset": "Filter zurücksetzen",
    "showresults": "Ergebnisse anzeigen",
    "suggestiontitle": "Forschungsvorschläge",
    "reminder": "Vergessen Sie nicht :\n Ich kann Ihre Fragen beantworten."
  }
}
