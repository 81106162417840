import React from 'react'
import { useViewStore } from '../../Contexts/viewStore'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { Mode as ModeEnum } from '../../../models/enums'
import { LanguageButton, Parameters } from '../Common'
import Mode from './Mode'

const Remote = (): JSX.Element => {
  const disableSound = useRetorikStore(
    (state) => state.configuration.disableSound
  )
  const isMobile = useViewStore((state) => state.isMobile)
  const mode = useRetorikStore((state) => state.mode)
  const configuration = useRetorikStore((state) => state.configuration)

  return (
    <div
      id='retorik-framework-remote'
      className='rf-relative rf-w-full rf-pt-3 rf-pb-4 rf-flex rf-flex-col rf-items-center rf-rounded-l-lg rf-shadow-[0_3px_6px_#606267]'
      style={{
        background: 'linear-gradient(#F8F8FC, #EDEEF3)'
      }}
    >
      {/* Modes + vocal input button */}
      <Mode />
      {((!disableSound && mode === ModeEnum.text) ||
        mode === ModeEnum.vocal ||
        isMobile) && (
        <>
          {/* Separation line */}
          <div className='rf-w-[90%] rf-mb-4 rf-border-b-2 rf-border-b-[#C4C5C8]' />

          {/* Parameters */}
          <div className='rf-w-full rf-flex rf-flex-col rf-justify-center rf-gap-5 large-vertical:rf-gap-6'>
            <Parameters />
          </div>
        </>
      )}

      {configuration.languageChoiceBottom?.setUnderRemote && <LanguageButton />}
    </div>
  )
}

export default Remote
