import React, { useState, useEffect, useMemo } from 'react'
import { ConnectionStatus } from 'botframework-directlinejs'

import {
  addCookie,
  setAddRetorikNewsConversationIdInLocalStorage,
  setRetrievingConversation
} from '../Contexts/activityStore'
import {
  useDirectLineStore,
  createDirectLine,
  resetDirectlineStore
} from '../Contexts/directLineStore'
import { useLocaleStore } from '../Contexts/localeStore'
import { useRetorikStore, setAppAvailable } from '../Contexts/retorikStore'

import type { WithChildren } from '../../models/utils'
import type { AddressData, UserData } from '../../models/types'
import type {
  DirectLineCreationData,
  DirectLineData
} from '../../models/directLine'
import { fetchDirectLineToken } from '../../utils/fetchDirectLineToken'

import SendActivityEventListener from '../Utils/SendActivityEventListener'

type RetorikComposerProps = WithChildren<{
  addressData: AddressData
  userData?: UserData
  externalEventHandler?: (action: any, dispatch?: any) => boolean
  height?: number | 'full'
  width?: number | 'full'
  isRetorikNews: boolean
}>

const defaultUser = {
  name: 'default',
  id: `defaultId_${Date.now()}`,
  username: 'default',
  nom: 'defaultName',
  prenom: 'à vous',
  email: '',
  token: '',
  referrer: '',
  ipaddress: ''
}

const RetorikComposer = ({
  addressData,
  userData,
  externalEventHandler,
  children,
  isRetorikNews
}: RetorikComposerProps): JSX.Element => {
  const directLine = useDirectLineStore((state) => state.directLine)
  const connectionStatus = useDirectLineStore((state) => state.directLineStatus)
  const [directLineCreationData, setDirectLineCreationData] =
    useState<DirectLineCreationData>()
  const configuration = useRetorikStore((state) => state.configuration)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const agentData = useRetorikStore((state) => state.agentData)
  const locale = useLocaleStore((state) => state.locale)
  const supported = useLocaleStore((state) => state.supported)

  const [hasConversationCookie, setHasConversationCookie] =
    useState<boolean>(false)

  const processedUserData = useMemo<UserData>(() => {
    if (userData) {
      return userData
    } else {
      const userIdFromCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('retorikUserIdCookie='))
        ?.split('=')[1]

      if (userIdFromCookie) {
        return {
          ...defaultUser,
          username: userIdFromCookie,
          id: userIdFromCookie
        }
      } else {
        const id = `defaultId_${Date.now()}`
        addCookie(`retorikUserIdCookie=${id}`)

        return {
          ...defaultUser,
          username: id,
          id: id
        }
      }
    }
  }, [userData])

  const processDirectLine = async (
    directLineData: DirectLineData
  ): Promise<void> => {
    fetchDirectLineToken(directLineData.tokenEndpoint).then((token) => {
      let test = true
      if (isRetorikNews) {
        const fullTenantName = (
          addressData.baseURI ||
          `${addressData.prefix || ''}${addressData.tenant}`
        ).replace(/;|,|\s/g, '')

        const newsConversationData = localStorage.getItem(
          `Retorik.News.Conversation.${fullTenantName}`
        )

        if (newsConversationData) {
          const data = JSON.parse(newsConversationData)
          if (data?.conversationId) {
            // 604800000 = number of milliseconds in 7 days
            if (
              !isNaN(data.startedAt) &&
              data.startedAt + 604800000 > Date.now()
            ) {
              setDirectLineCreationData({
                token: token,
                domain: directLineData.address,
                conversationId: data.conversationId
              })
              test = false
            }
          }
        }

        test && setAddRetorikNewsConversationIdInLocalStorage(fullTenantName)
      } else if (configuration.enableConversationCookie !== false) {
        const conversationCookie = document.cookie
          .split('; ')
          .find((row) => row.startsWith('retorikConversationCookie='))
          ?.split('=')[1]

        if (conversationCookie) {
          const splitCookieData = conversationCookie.split('||')
          if (splitCookieData.length === 2) {
            const cookieConversationId = splitCookieData[0]
            const cookieTenantName = splitCookieData[1]
            const fullTenantName = (
              addressData.baseURI ||
              `${addressData.prefix || ''}${addressData.tenant}`
            ).replace(/;|,|\s/g, '')

            if (cookieTenantName === fullTenantName) {
              setDirectLineCreationData({
                token: token,
                domain: directLineData.address,
                conversationId: cookieConversationId,
                watermark: '0'
              })

              // Tell the activity store that we will retrieve several activities from an existing conversation, so that it displays only the last one
              setRetrievingConversation(true)
              setHasConversationCookie(true)
              test = false
            }
          }
        } else {
          const fullTenantName = (
            addressData.baseURI ||
            `${addressData.prefix || ''}${addressData.tenant}`
          ).replace(/;|,|\s/g, '')
          const maxAge = configuration.conversationCookieMaxAge || 3600
          addCookie(
            `retorikConversationCookie=<conversationId>||${fullTenantName}; max-age=${maxAge}`
          )
        }
      }

      test &&
        setDirectLineCreationData({
          token: token,
          domain: directLineData.address
        })
    })
  }

  useEffect(() => {
    if (directLineCreationData && locale && processedUserData) {
      !directLine &&
        createDirectLine({
          ...directLineCreationData,
          conversationStartProperties: {
            locale: locale
          },
          userId: processedUserData.id,
          externalActivityHandler: externalEventHandler
        })
    }
  }, [locale, directLineCreationData, processedUserData])

  useEffect(() => {
    addressData?.directline && processDirectLine(addressData.directline)

    return () => {
      console.log('Retorik Framework > Disconnect and end directline')
      resetDirectlineStore()
    }
  }, [addressData])

  useEffect(() => {
    if (
      locale &&
      supported &&
      agentData &&
      connectionStatus === ConnectionStatus.Online &&
      (isRetorikNews || loaderClosed)
    ) {
      setAppAvailable(true)
    }
  }, [locale, supported, agentData, loaderClosed, connectionStatus])

  return directLine && agentData && processedUserData ? (
    <React.Fragment>
      <SendActivityEventListener
        isRetorikNews={isRetorikNews}
        hasConversationCookie={hasConversationCookie}
        userData={processedUserData}
      />
      {children}
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default RetorikComposer
