{
  "1": "ana sayfa",
  "2": "canlı bilgi kanalı",
  "3": "hava durumu",
  "4": "Acil durum numaraları",
  "1000": "tarih",
  "1001": "öğretici",

  "common": {
    "all": "Tümü",
    "back": "geri",
    "close": "kapalı",
    "continue": "Devam etmek",
    "download": "İndirmek",
    "downloadsheet": "bu sayfayı indir",
    "downloadsheetmobile": "bu sayfayı akıllı telefonunuza indirin",
    "filter": "Filtre",
    "history": "tarihi",
    "lang": "Diller",
    "menu": "Menü",
    "mode": "moda",
    "needhelp": "Yardıma mı ihtiyacınız var?",
    "parameters": "ayarlar",
    "print": "Yazdır",
    "printsheet": "Bu sayfayı yazdır",
    "result": "sonuç",
    "results": "sonuçlar",
    "singleLang": "dil",
    "sound": "ses",
    "submit": "göndermek",
    "subtitle": "alt yazı",
    "tutorial": "öğretici",
    "validate": "Doğrulamak"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "polis",
    "firefighter": "itfaiyeciler",
    "general": "genel",
    "hospital": "Hastane",
    "homeless": "Evsiz",
    "poison": "Zehir kontrol Merkezi",
    "emergency": "Acil servisler"
  },

  "error": {
    "title": "bir hata oluştu",
    "button": "Uygulamayı yeniden başlatın"
  },

  "history": {
    "title": "konuşma geçmişi"
  },

  "inputs": {
    "choose": "Bir cevap seçin"
  },

  "language": {
    "change": "dili değiştir",
    "select": "istediğiniz dili seçin"
  },

  "link": {
    "buttontext": "Belgeyi görüntüle",
    "goto": "Adrese git",
    "click": "buraya tıklayın",
    "scanQR": "QrCode'u tarayarak",
    "scanQRTile": "Keşfetmek için QR Kodu’nu tara"
  },

  "loader": {
    "loader": {
      "optimal": "Optimum bir deneyim için,",
      "classic": "Daha klasik bir deneyim için,",
      "vocal": "Temsilciyle konuşarak iletişim kuruyorum.",
      "text": "Temsilciyle mesaj yoluyla iletişim kuruyorum."
    },
    "modes": {
      "vocal": "vokal modu",
      "text": "metin modu"
    },
    "loaderValidation": "Konuşmayı başlat",
    "locale": "dili kontrol etme",
    "supported": "desteklenen dilleri kontrol etme",
    "addressdata": "veri alma"
  },

  "menu": {
    "message": "Mesajınızı girin",
    "allowMicrophone": "Devam etmek için tarayıcınızın mikrofonunuzu kullanmasına izin verin",
    "backconversation": "konuşmaya geri dön",
    "answer": " sorularınızı yanıtlıyor"
  },

  "mobileLandscapePreventer": {
    "firstline": "Optimum bir deneyim için,",
    "secondline": "ekranını çevir"
  },

  "news": {
    "warningtext": "Haber kanalına ulaşacaksınız, cihazınızdaki ses aktif hale gelecektir",
    "live": "canlı",
    "continuous": "devamlı olarak"
  },

  "poi": {
    "comment": "müşteri yorumu",
    "route": "yol",
    "nodata": "Aramanızla eşleşen içerik yok",
    "openinghours": "Açılış saatleri",
    "prices": "Fiyat:% s",
    "paymentmethods": "Ödeme metodları",
    "numberofplaces": "yer sayısı",
    "openroutemobile": "akıllı telefonda Google Haritalar rotasını aç",
    "FoodEstablishment": "Nerede yenir",
    "LodgingBusiness": "Nerede uyumalı",
    "TouristDestination": "Ne görmeli",
    "TouristAttraction": "Ne yapalım",
    "Product": "Yerel ürünler",
    "Service": "Hizmetler",
    "Event": "Olaylar",
    "City": "Şehirler",
    "Wine": "Şaraplar"
  },

  "suggestion": {
    "consult": "Çıkış yapmak",
    "thetutorial": "öğretici"
  },

  "template": {
    "reset": "hepsini sıfırla",
    "showresults": "Sonuçları göster",
    "suggestiontitle": "Önerileri ara",
    "reminder": "Unutmayın, \n sorularınızı cevaplayabilirim."
  }
}
