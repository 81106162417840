import React, { ReactNode, useEffect } from 'react'

import { TelemetryProvider } from './TelemetryContext'
import {
  setAgentData,
  setConfiguration,
  setMode,
  setDisableSound,
  setAddressData,
  setSkipLoader,
  setSubtitlesInConfiguration
} from './retorikStore'
import { useLocaleStore, setLocale } from './localeStore'
import {
  setCustomVoice,
  setPonyfillFactoryCredentials,
  setUseContinuousRecognition,
  setMuted
} from './speechStore'
import { setViewsConfiguration, useViewStore } from './viewStore'
import { setPositionToSend } from './directLineStore'

import type {
  AddressData,
  Configuration,
  CustomVoice,
  PonyfillFactoryCredentials,
  ViewsConfiguration
} from '../../models/types'
import { checkLocale } from '../../utils/checkLocale'

interface SpeechProviderProps {
  ponyfillFactoryCredentials: PonyfillFactoryCredentials
  customVoice: CustomVoice | undefined
}

interface ViewsProviderProps {
  viewsConfiguration: ViewsConfiguration
}

interface ContextProviderProps extends SpeechProviderProps, ViewsProviderProps {
  skipLoader?: boolean
  mode: number
  configuration: Configuration
  addressData: AddressData
  agentSource:
    | string
    | {
        url: string
        name?: string
      }
  children: ReactNode
}

interface LocationData {
  searchForLocation: boolean
  latitude: number
  longitude: number
  city?: string
  country?: string
}

const ContextProvider = ({
  skipLoader,
  mode,
  viewsConfiguration,
  configuration,
  customVoice,
  addressData,
  agentSource,
  ponyfillFactoryCredentials,
  children
}: ContextProviderProps): JSX.Element | null => {
  const locale = useLocaleStore((state) => state.locale)
  const supported = useLocaleStore((state) => state.supported)
  const defaultLocale = useLocaleStore((state) => state.defaultLocale)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)

  const setLocation = async (data): Promise<void> => {
    const position: LocationData = {
      searchForLocation: true,
      latitude: data.coords.latitude,
      longitude: data.coords.longitude
    }

    setPositionToSend({
      latitude: position.latitude,
      longitude: position.longitude
    })

    setConfiguration({
      ...configuration,
      position: position
    })
  }

  useEffect(() => {
    // Set continous recognition state
    setUseContinuousRecognition(
      currentDeviceType,
      configuration?.speechRecognitionOptions
    )
  }, [addressData])

  useEffect(() => {
    setMode(mode)
  }, [mode])

  useEffect(() => {
    setSkipLoader(!!skipLoader)
  }, [skipLoader])

  useEffect(() => {
    setViewsConfiguration(viewsConfiguration)
  }, [viewsConfiguration, ...Object.values(viewsConfiguration)])

  useEffect(() => {
    setConfiguration(configuration)
  }, [configuration, ...Object.values(configuration)])

  useEffect(() => {
    if (configuration.position?.searchForLocation) {
      // Position enabled
      if (
        configuration.position.latitude === undefined &&
        configuration.position.longitude === undefined
      ) {
        // Try to get the position of the device if no one has been given in the configuration
        navigator.geolocation?.getCurrentPosition(
          setLocation,
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.warn(
                  'Geolocation : User denied the request for Geolocation.'
                )
                break
              case error.POSITION_UNAVAILABLE:
                console.warn(
                  'Geolocation : Location information is unavailable.'
                )
                break
              case error.TIMEOUT:
                console.warn(
                  'Geolocation : The request to get user location timed out.'
                )
                break
              default:
                console.warn('Geolocation : An unknown error occurred.')
                break
            }
          },
          {
            timeout: 3000
          }
        )
      }
    }
  }, [configuration.position])

  useEffect(() => {
    configuration.position?.latitude &&
      configuration.position.longitude &&
      setPositionToSend({
        latitude: configuration.position.latitude,
        longitude: configuration.position.longitude
      })
  }, [configuration.position?.latitude, configuration.position?.longitude])

  useEffect(() => {
    if (configuration.disableSound === true) {
      setMuted(true)
      setDisableSound(true)
    }
    if (configuration.disableSound === false) {
      setDisableSound(false)
    }
  }, [configuration.disableSound])

  useEffect(() => {
    setSubtitlesInConfiguration(!!configuration.subtitles)
  }, [configuration.subtitles])

  useEffect(() => {
    setCustomVoice(customVoice)
  }, [customVoice])

  useEffect(() => {
    setAddressData(addressData)
  }, [addressData])

  useEffect(() => {
    setAgentData(agentSource)
  }, [agentSource])

  useEffect(() => {
    setPonyfillFactoryCredentials(ponyfillFactoryCredentials)
  }, [ponyfillFactoryCredentials])

  useEffect(() => {
    if (supported.length) {
      setLocale(
        checkLocale(
          { all: supported, default: defaultLocale },
          configuration.locales
        )
      )
    }
  }, [supported, configuration])

  return locale && supported ? (
    <TelemetryProvider enabled={!(configuration.enableTelemetry === false)}>
      {children}
    </TelemetryProvider>
  ) : (
    <React.Fragment />
  )
}

export default ContextProvider
