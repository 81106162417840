import React, { useMemo, useState } from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useSpeechStore } from '../Contexts/speechStore'
import { useUtilsStore, setListClosed } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'

import type { RetorikActivity } from '../../models/activityTypes'
import { DeviceType, Displays } from '../../models/enums'

import ListTemplate from '../Templates/List'
import Attachments from '../Attachments/Attachments'

const BotResponse = (): JSX.Element => {
  const showHomeAttachments = useViewStore((state) => state.showHomeAttachments)
  const speaking = useSpeechStore((state) => state.speaking)
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const currentOrLastPlayedActivity = useSpeechStore(
    (state) => state.currentOrLastPlayedActivity
  )
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const currentWidth = useViewStore((state) => state.currentWidth)
  const currentHeight = useViewStore((state) => state.currentHeight)
  const configuration = useRetorikStore((state) => state.configuration)
  const displaySubtitles = useRetorikStore((state) => state.displaySubtitles)
  const listClosed = useUtilsStore((state) => state.listClosed)

  const [gridRowStart, setGridRowStart] = useState<number>()
  const [gridRowSpan, setGridRowSpan] = useState<number>()

  const botResponseClassname = useMemo<string>(() => {
    let classname =
      'rf-relative rf-w-full rf-h-full rf-overflow-y-scroll rf-scrollbar-thin'

    switch (currentDeviceType) {
      case DeviceType.mobile:
      case DeviceType.widget: {
        // Special case for very small screens
        const isSmall = currentHeight < 700 && currentWidth < 300
        classname += ' rf-col-start-1 rf-col-span-full'
        setGridRowStart(isSmall ? 5 : 6)
        if (displaySubtitles && speaking) {
          setGridRowSpan(isSmall ? 6 : 5)
        } else {
          setGridRowSpan(isSmall ? 7 : 6)
        }
        break
      }
      case DeviceType.landscape:
      case DeviceType.widgetLandscape: {
        classname += ' rf-col-start-5 rf-col-span-full'

        if (
          typeof configuration.answerpanel === 'object' &&
          configuration.answerpanel.landscape
        ) {
          const start = configuration.answerpanel.landscape.containerRowStart
          const end = configuration.answerpanel.landscape.containerRowEnd

          if (start && start > 0 && start < 13) {
            if (end && end < 13) {
              if (start < end) {
                setGridRowStart(start)
                setGridRowSpan(end - start)
              }
            } else {
              setGridRowStart(start)
              setGridRowSpan(13 - start)
            }
          } else if (end && end > 0 && end < 13) {
            setGridRowStart(1)
            setGridRowSpan(end - 1)
          } else {
            setGridRowStart(5)
            setGridRowSpan(8)
          }
        } else {
          setGridRowStart(5)
          setGridRowSpan(8)
        }
        break
      }
      case DeviceType.borne:
      case DeviceType.widgetBorne: {
        classname += ' rf-col-start-1 rf-col-span-full'

        if (
          typeof configuration.answerpanel === 'object' &&
          configuration.answerpanel.borne
        ) {
          const start = configuration.answerpanel.borne.containerRowStart
          const end = configuration.answerpanel.borne.containerRowEnd

          if (start && start > 0 && start < 13) {
            if (end && end < 13) {
              if (start < end) {
                setGridRowStart(start)
                setGridRowSpan(end - start)
              }
            } else {
              setGridRowStart(start)
              setGridRowSpan(13 - start)
            }
          } else if (end && end > 0 && end < 13) {
            setGridRowStart(1)
            setGridRowSpan(end - 1)
          } else {
            setGridRowStart(7)
            setGridRowSpan(4)
          }
        } else {
          setGridRowStart(7)
          setGridRowSpan(4)
        }
        break
      }
    }

    return classname
  }, [
    currentDeviceType,
    displaySubtitles,
    speaking,
    configuration.answerpanel,
    currentWidth,
    currentHeight
  ])

  const currentActivity = useMemo<RetorikActivity | undefined>(() => {
    return showHomeAttachments || currentOrLastPlayedActivity
  }, [currentOrLastPlayedActivity, showHomeAttachments])

  const currentDisplay = useMemo<Displays>(() => {
    if (currentActivity) {
      if (currentActivity.attachmentLayout) {
        switch (currentActivity.attachmentLayout.toLowerCase()) {
          case 'davilist':
            return Displays.list
          case 'carousel':
            return currentActivity.attachments &&
              currentActivity.attachments.length > 1
              ? Displays.carousel
              : Displays.normal
          case 'grid':
            return Displays.grid
          default:
            return Displays.none
        }
      }

      // If we have no attachment layout but attachment(s), we use list display by default is there are several attachments
      if (currentActivity.attachments?.length) {
        return currentActivity.attachments?.length > 1
          ? Displays.list
          : Displays.normal
      }
    }

    return Displays.none
  }, [currentActivity])

  return currentDisplay === Displays.none ? (
    <React.Fragment />
  ) : currentDisplay === Displays.list ? (
    lastRecognitionInterim === '' && !listClosed ? (
      <ListTemplate
        activity={currentActivity}
        onClose={(): void => setListClosed(true)}
      />
    ) : (
      <React.Fragment />
    )
  ) : (
    <div
      id='retorik-botResponse'
      className={botResponseClassname}
      style={{
        gridRow: `${gridRowStart} / span ${gridRowSpan}`
      }}
    >
      {currentActivity && (
        <div className='rf-w-full rf-h-full rf-flex rf-flex-col rf-items-center rf-gap-2'>
          <Attachments activity={currentActivity} />
        </div>
      )}
    </div>
  )
}

export default BotResponse
