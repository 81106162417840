import React, { useEffect, useState } from 'react'
import { hexToRgb } from '../../../utils/colorUtils'

interface SvgColorChangerProps {
  svgUrl: string // L'URL de l'image SVG
  fillColor?: string // La couleur finale souhaitée
  height?: string // La hauteur de l'image
}

const SvgColorChanger = ({
  svgUrl,
  fillColor,
  height
}: SvgColorChangerProps) => {
  const [filter, setFilter] = useState<string>()

  const rgbToCssFilter = (r: number, g: number, b: number): string => {
    const hex = `${(r & 0xff).toString(16).padStart(2, '0')}${(g & 0xff)
      .toString(16)
      .padStart(2, '0')}${(b & 0xff).toString(16).padStart(2, '0')}`

    return `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><defs><filter id="${hex}" color-interpolation-filters="sRGB"><feColorMatrix type="matrix" values="0 0 0 0 ${
      r / 255.0
    } 0 0 0 0 ${g / 255.0} 0 0 0 0 ${
      b / 255.0
    } 0 0 0 1 0"/></filter></defs></svg>#${hex}')`
  }

  useEffect(() => {
    if (fillColor) {
      const rgbColor = hexToRgb(fillColor)
      setFilter(rgbToCssFilter(rgbColor.r, rgbColor.g, rgbColor.b))
    }
  }, [fillColor])

  return (
    <img
      className='rf-filter'
      style={{
        height: height || '100%',
        filter: filter
      }}
      src={svgUrl}
    />
  )
}

export default SvgColorChanger
