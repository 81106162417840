{
  "1": "होम पेज",
  "2": "लाइव न्यूज़ चैनल",
  "3": "मौसम की रिपोर्ट",
  "4": "आपातकालीन नंबर",
  "1000": "ऐतिहासिक",
  "1001": "ट्यूटोरियल",

  "common": {
    "all": "सभी",
    "back": "पीछे",
    "close": "बंद करना",
    "continue": "जारी रखना",
    "download": "डाउनलोड करना",
    "downloadsheet": "इस शीट को डाउनलोड करें",
    "downloadsheetmobile": "इस शीट को स्मार्टफोन पर डाउनलोड करें",
    "filter": "फ़िल्टर",
    "history": "ऐतिहासिक",
    "lang": "बोली",
    "menu": "मेन्यू",
    "mode": "पहनावा",
    "needhelp": "मदद की ज़रूरत है?",
    "parameters": "समायोजन",
    "print": "छाप",
    "printsheet": "इस शीट को प्रिंट करें",
    "result": "परिणाम",
    "results": "परिणाम",
    "singleLang": "भाषा",
    "sound": "उसकी",
    "submit": "जमा करना",
    "subtitle": "उपशीर्षक",
    "tutorial": "ट्यूटोरियल",
    "validate": "मान्य"
  },

  "emergency": {
    "samu": "एस.ए.एम.यू.",
    "police": "पुलिस",
    "firefighter": "अग्निशमन कर्मी",
    "general": "जनरल",
    "citypolice": "स्थानीय पुलिस",
    "hospital": "अस्पताल",
    "homeless": "बेघर (निःशुल्क कॉल)",
    "poison": "ज़हर नियंत्रण केंद्र",
    "emergency": "यूरोपीय आपातकाल"
  },

  "error": {
    "title": "एक गलती हुई है",
    "button": "एप्लिकेशन को पुनः लॉन्च करें"
  },

  "history": {
    "title": "बातचीत का इतिहास"
  },

  "inputs": {
    "choose": "एक उत्तर चुनें"
  },

  "language": {
    "change": "भाषा बदलो",
    "select": "वह भाषा चुनें जिसका आप उपयोग करना चाहते हैं"
  },

  "link": {
    "buttontext": "दस्तावेज़ देखें",
    "goto": "पते पर जाएं",
    "click": "यहाँ क्लिक करें",
    "scanQR": "QrCode को स्कैन करके",
    "scanQRTile": "क्यूआर कोड स्कैन करें और जानें"
  },

  "loader": {
    "loader": {
      "optimal": "सर्वोत्तम अनुभव के लिए,",
      "classic": "अधिक क्लासिक अनुभव के लिए,",
      "vocal": "मैं एजेंट से आमने-सामने बात करता हूं।",
      "text": "मैं एजेंट के साथ लिखित रूप से संवाद करता हूं।"
    },
    "modes": {
      "vocal": "स्वर विधा",
      "text": "पाठ मोड"
    },
    "loaderValidation": "बातचीत शुरू करें",
    "locale": "भाषा की जाँच करना",
    "supported": "समर्थित भाषाओं की जाँच करना",
    "addressdata": "पुन: प्राप्त डेटा"
  },

  "menu": {
    "message": "अपना संदेश दर्ज करें",
    "allowMicrophone": "जारी रखने के लिए अपने ब्राउज़र को अपने माइक्रोफ़ोन का उपयोग करने की अनुमति दें",
    "backconversation": "बातचीत पर वापस",
    "answer": " आपके प्रश्नों का उत्तर देता है"
  },

  "mobileLandscapePreventer": {
    "firstline": "सर्वोत्तम अनुभव के लिए,",
    "secondline": "अपनी स्क्रीन पलटें"
  },

  "news": {
    "warningtext": "आप समाचार चैनल तक पहुंच पाएंगे, आपके डिवाइस पर ध्वनि सक्रिय हो जाएगी",
    "live": "रहना",
    "continuous": "लगातार"
  },

  "poi": {
    "comment": "ग्राहक समीक्षा",
    "route": "सड़क",
    "nodata": "कोई भी सामग्री आपकी खोज से मेल नहीं खाती",
    "openinghours": "खुलने का समय",
    "prices": "कीमतों",
    "paymentmethods": "भुगतान की विधि",
    "numberofplaces": "स्थानों की संख्या",
    "openroutemobile": "स्मार्टफोन पर गूगल मैप रूट खोलें",
    "FoodEstablishment": "कहाँ खाना है",
    "LodgingBusiness": "कहाँ सोना है",
    "TouristDestination": "क्या देखें",
    "TouristAttraction": "क्या करें",
    "Product": "स्थानीय उत्पाद",
    "Service": "सेवाएं",
    "Event": "आयोजन",
    "City": "शहरों",
    "Wine": "वाइन"
  },

  "suggestion": {
    "consult": "चेक आउट",
    "thetutorial": "ट्यूटोरियल"
  },

  "template": {
    "reset": "सभी को पुनः तैयार करना",
    "showresults": "परिणाम दिखाएँ",
    "suggestiontitle": "सुझाव खोजें",
    "reminder": "मत भूलिए, \n मैं आपके प्रश्नों का उत्तर दे सकता हूँ।"
  }
}
