import { hospitalityApiAddress } from '../models/constants'
import { EmergencyTypes } from '../models/emergencyTypes'

async function fetchEmergencyData(address: string): Promise<EmergencyTypes> {
  const res = await fetch(`${address}${hospitalityApiAddress}`)
    .then((emergencyData) => {
      return emergencyData.json()
    })
    .catch(() => {
      return null
    })

  return res
}

export { fetchEmergencyData }
