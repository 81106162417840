import React, { useEffect, useMemo, useRef, useState } from 'react'
import { animated, useSpring, easings } from '@react-spring/web'
import MarkdownIt from 'markdown-it'
import QRCode from 'qrcode'

import { sendMessage, sendEvent } from '../Contexts/directLineStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import {
  setCurrentSubView,
  setDashboardOpened,
  setFullScreenImage
} from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'
import { useLocaleStore } from '../Contexts/localeStore'
import useRefDimensions from '../../hooks/useRefDimensions'

import { ActionType, CurrentSubView } from '../../models/enums'

import SvgColorChanger from './Utils/SvgColorChanger'
import QRCodeComponent from '../Utils/QRCodeComponent'
import CloseButton from '../Common/CloseButton'
import { RoundIcon } from '../Icons/DetailedPOIIcons'

interface GridAttachmentProps {
  index: number
  text: string
  textColor?: string
  backgroundColor?: string
  image?: {
    url: string
    color?: string
  }
  action: {
    type: string
    value?: Record<string, any>
  }
  history?: boolean
}

const springEnterDuration = 1000
const springQrCodeDuration = 500

const md = new MarkdownIt({
  breaks: true,
  html: true,
  xhtmlOut: true,
  typographer: true,
  quotes: `""''`
})

const GridAttachment = ({
  index,
  text,
  textColor,
  backgroundColor,
  image,
  action,
  history
}: GridAttachmentProps): JSX.Element => {
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const isMobile = useViewStore((state) => state.isMobile)
  const translation = useLocaleStore((state) => state.currentTranslations)
  const [qrCodeData, setQrCodeData] = useState<string | undefined>(undefined)
  const fadeInTimerRef = useRef<NodeJS.Timer | null>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const tileDimensions = useRefDimensions(divRef)

  const height = useMemo<number>(() => {
    return tileDimensions.width * 0.75
  }, [tileDimensions.width])

  const showImage = useMemo<boolean>(() => {
    return !!(image?.url && height > 80)
  }, [height, image])

  const nbLines = useMemo<number>(() => {
    if (!showImage) {
      const remToPx = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      )
      const lineHeightInPx = remToPx * (isMobile ? 1 : 1.1)

      return Math.floor(height / lineHeightInPx)
    }

    return 2
  }, [showImage, height])

  const lineClamp = useMemo<string>(() => {
    return showImage ? 'rf-h-2/5 rf-line-clamp-2' : `rf-line-clamp-${nbLines}`
  }, [nbLines, showImage])

  const maxHeight = useMemo<string>(() => {
    return showImage
      ? 'rf-max-h-8 large:rf-max-h-[2.3rem]'
      : `rf-max-h-[${nbLines}rem] large:rf-max-h-[${nbLines * 1.1 + 0.1}rem]`
  }, [nbLines, showImage])

  const [spring, api] = useSpring(() => ({
    from: {
      transform: 'translateX(0%)',
      opacity: 0
    }
  }))

  const [qrCodeSpring, qrCodeApi] = useSpring(() => ({
    from: {
      opacity: 0
    }
  }))

  useEffect(() => {
    setTimeout(() => {
      api.start({
        from: {
          transform: 'translateX(100%)',
          opacity: 0
        },
        to: {
          transform: 'translateX(0%)',
          opacity: 1
        },
        config: {
          duration: springEnterDuration,
          easing: easings.easeInBack
        }
      })
    }, index * 200)
  }, [])

  useEffect(() => {
    qrCodeData &&
      qrCodeApi.start({
        from: {
          opacity: 0
        },
        to: {
          opacity: 1
        },
        config: {
          duration: springQrCodeDuration
        }
      })

    return () => {
      fadeInTimerRef?.current && clearTimeout(fadeInTimerRef.current)
    }
  }, [qrCodeData])

  const handleCloseQrCode = (): void => {
    qrCodeApi.start({
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      },
      config: {
        duration: springQrCodeDuration
      }
    })

    fadeInTimerRef?.current && clearTimeout(fadeInTimerRef.current)
    fadeInTimerRef.current = setTimeout(() => {
      setQrCodeData(undefined)
    }, springQrCodeDuration)
  }

  const setQrCodeDataAsync = async (url: string): Promise<void> => {
    const qrCodeString = await QRCode.toDataURL(url)
    setQrCodeData(qrCodeString)
  }

  const handleInternalAction = (
    value: Record<string, any> | undefined
  ): void => {
    if (value?.name) {
      switch (value.name.toLowerCase()) {
        case 'openmenu':
          setDashboardOpened(true)
          break
        case 'openlanguage':
          setCurrentSubView(CurrentSubView.languages)
          break
        case 'openhistory':
          setCurrentSubView(CurrentSubView.history)
          break
        case 'opentutorial':
          console.log('Open tutorial')
          break
        case 'fullscreenimage':
          value.url && setFullScreenImage(value.url)
          break
      }
    }
  }

  const handleClick = (): void => {
    switch (action.type) {
      case ActionType.message:
        action.value?.text && sendMessage(action.value.text)
        break
      case ActionType.event:
        action.value?.name &&
          sendEvent(action.value.name, action.value.value || null)
        break
      case ActionType.url:
        if (action.value?.url) {
          // Generate QR-code data on borne, open the URL otherwise
          isUsedOnBorne
            ? setQrCodeDataAsync(action.value.url)
            : window.open(action.value.url, '_blank', 'noopener noreferrer')
        }
        break
      case ActionType.internal:
        handleInternalAction(action.value)
        break
    }
  }

  return (
    <animated.div
      ref={divRef}
      className={`rf-relative rf-w-full ${
        history ? 'rf-p-3 rf-pointer-events-none' : 'rf-p-4'
      } rf-flex rf-flex-col ${
        image?.url ? 'rf-justify-between' : 'rf-justify-end'
      } rf-items-start rf-gap-[10%] ${
        isMobile ? 'rf-border rf-border-[#F0F0F0]' : ''
      } hover:rf-shadow-[inset_0_0_12px_#00000029] hover:rf-cursor-pointer rf-rounded rf-shadow-[-3px_-3px_10px_#00000099] rf-overflow-hidden`}
      style={{
        height: `calc(${height}px + 1.5rem)`,
        color: textColor || '#575F6B',
        backgroundColor: backgroundColor || '#FFF',
        ...spring
      }}
      onClick={handleClick}
    >
      {/* Image */}
      {showImage && (
        <div className='rf-h-1/2'>
          {image?.url && image.url.indexOf('svg') > -1 ? (
            <SvgColorChanger
              svgUrl={image.url}
              height='100%'
              fillColor={image.color}
            />
          ) : (
            <img className='rf-h-full' src={image?.url} />
          )}
        </div>
      )}

      {/* Text */}
      <div
        className={`rf-flex rf-min-h-8 large:rf-min-h-[2.3rem] ${lineClamp} rf-items-start rf-text-small-size-auto rf-text-left rf-overflow-y-hidden`}
      >
        <p
          className={`${maxHeight} rf-overflow-y-clip`}
          dangerouslySetInnerHTML={{
            __html: md.renderInline(text)
          }}
        />
      </div>

      {/* QR code */}
      {qrCodeData && (
        <animated.div
          className='rf-absolute rf-h-full rf-w-full rf-top-0 rf-left-0 rf-z-ui rf-flex rf-flex-col rf-justify-center rf-items-center rf-gap-2 rf-p-2 rf-text-truewhite'
          style={{
            background: 'rgba(18,18,18,0.7)',
            ...qrCodeSpring
          }}
        >
          <CloseButton onClick={handleCloseQrCode} showLabel={false} />

          {/* Icon + Text */}
          <div className='rf-px-8 rf-flex rf-flex-row rf-items-start rf-gap-1 rf-italic'>
            <RoundIcon color='#FFF' />
            <div className='rf-w-fit'>{translation.link.scanQRTile}</div>
          </div>

          {/* QR code */}
          <QRCodeComponent src={qrCodeData} />
        </animated.div>
      )}
    </animated.div>
  )
}

export default GridAttachment
