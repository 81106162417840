{
  "1": "hjemmeside",
  "2": "live nyhedskanal",
  "3": "vejrudsigt",
  "4": "Nødnumre",
  "1000": "historisk",
  "1001": "tutorial",

  "common": {
    "all": "alle",
    "back": "tilbage",
    "close": "tæt",
    "continue": "Blive ved",
    "download": "Hent",
    "downloadsheet": "download dette ark",
    "downloadsheetmobile": "download dette ark på smartphone",
    "filter": "Filter",
    "history": "historisk",
    "lang": "SPROG",
    "menu": "menu",
    "mode": "mode",
    "needhelp": "Brug for hjælp?",
    "parameters": "indstillinger",
    "print": "Print",
    "printsheet": "Udskriv dette ark",
    "result": "resultat",
    "results": "resultater",
    "singleLang": "Sprog",
    "sound": "hende",
    "submit": "indsend",
    "subtitle": "undertekst",
    "tutorial": "tutorial",
    "validate": "validere"
  },

  "emergency": {
    "samu": "S.A.M.U.",
    "police": "politi",
    "firefighter": "brandmænd",
    "general": "generel",
    "citypolice": "Lokalt politi",
    "hospital": "Hospital",
    "homeless": "Hjemløs (gratis opkald)",
    "poison": "Giftkontrolcenter",
    "emergency": "europæiske nødsituationer"
  },

  "error": {
    "title": "Der opstod en fejl",
    "button": "Genstart applikationen"
  },

  "history": {
    "title": "samtale historie"
  },

  "inputs": {
    "choose": "Vælg et svar"
  },

  "language": {
    "change": "ændre sproget",
    "select": "vælg det sprog, du vil bruge"
  },

  "link": {
    "buttontext": "Se dokumentet",
    "goto": "Gå til adressen",
    "click": "Klik her",
    "scanQR": "ved at scanne QRCoden",
    "scanQRTile": "Scan QR-koden for at opdage"
  },

  "loader": {
    "loader": {
      "optimal": "For en optimal oplevelse,",
      "classic": "For en mere klassisk oplevelse,",
      "vocal": "Jeg taler ansigt til ansigt med agenten.",
      "text": "Jeg kommunikerer skriftligt med agenten."
    },
    "modes": {
      "vocal": "vokal tilstand",
      "text": "teksttilstand"
    },
    "loaderValidation": "Start samtale",
    "locale": "kontrol af sprog",
    "supported": "kontrol af understøttede sprog",
    "addressdata": "hente data"
  },

  "menu": {
    "message": "Indtast din besked",
    "allowMicrophone": "Tillad din browser at bruge din mikrofon for at fortsætte",
    "backconversation": "tilbage til samtalen",
    "answer": " svarer på dine spørgsmål"
  },

  "mobileLandscapePreventer": {
    "firstline": "For en optimal oplevelse,",
    "secondline": "vend din skærm"
  },

  "news": {
    "warningtext": "Du får adgang til nyhedskanalen, lyden på din enhed aktiveres",
    "live": "Direkte",
    "continuous": "løbende"
  },

  "poi": {
    "comment": "kundeanmeldelse",
    "route": "vej",
    "nodata": "Intet indhold matcher din søgning",
    "openinghours": "Åbningstider",
    "prices": "Priser",
    "paymentmethods": "Betalingsmetoder",
    "numberofplaces": "antal pladser",
    "openroutemobile": "åben Google Map-rute på smartphone",
    "FoodEstablishment": "Hvor skal man spise",
    "LodgingBusiness": "Hvor skal man sove",
    "TouristDestination": "Hvad at se",
    "TouristAttraction": "Hvad skal man gøre",
    "Product": "Lokale produkter",
    "Service": "Tjenester",
    "Event": "Begivenheder",
    "City": "Byer",
    "Wine": "Vine"
  },

  "suggestion": {
    "consult": "Tjek ud",
    "thetutorial": "tutorialen"
  },

  "template": {
    "reset": "genstart alt",
    "showresults": "Vis resultaterne",
    "suggestiontitle": "Søgeforslag",
    "reminder": "Glem ikke, \n jeg kan svare på dine spørgsmål."
  }
}
