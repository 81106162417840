import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useViewStore } from '../Contexts/viewStore'
import useRefDimensions from '../../hooks/useRefDimensions'
import { Mode } from '../../models/enums'

interface GridProps {
  title?: string
  history?: boolean
  children: ReactNode
}

const Grid = ({ title, history, children }: GridProps): JSX.Element => {
  const mode = useRetorikStore((state) => state.mode)
  const mainWidth = useViewStore((state) => state.currentWidth)
  const mainHeight = useViewStore((state) => state.currentHeight)
  const [classname, setClassname] = useState<string>('')
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const gridContainerDimensions = useRefDimensions(gridContainerRef)

  const nbCards = useMemo<number>(() => {
    return React.Children.count(children)
  }, [children])

  const rowGap = useMemo<number>(() => {
    return gridContainerDimensions.width * 0.035
  }, [gridContainerDimensions.width])

  const maxHeight = useMemo<number>(() => {
    // 0.58 quite equals 7/12. This ratio prevents the container from overflowing vertically
    return mainHeight * 0.58
  }, [mainHeight])

  useEffect(() => {
    switch (nbCards) {
      case 2:
        setClassname('rf-grid-cols-[repeat(2,30%)] rf-grid-rows-1')
        break
      case 3:
        setClassname(
          mainHeight > mainWidth || (mainHeight < mainWidth && mainWidth > 1200)
            ? 'rf-grid-cols-[repeat(3,31%)] rf-grid-rows-1'
            : 'rf-grid-cols-[repeat(2,45%)] rf-grid-rows-2'
        )
        break
      case 4:
        setClassname('rf-grid-cols-[repeat(2,30%)] rf-grid-rows-2')
        break
      case 5:
      case 6:
        setClassname(
          mainHeight > mainWidth || (mainHeight < mainWidth && mainWidth > 1200)
            ? 'rf-grid-cols-[repeat(3,31%)] rf-grid-rows-2'
            : 'rf-grid-cols-[repeat(2,45%)] rf-grid-rows-3'
        )
        break
      case 7:
      case 8:
        setClassname(
          mainHeight > mainWidth || (mainHeight < mainWidth && mainWidth > 1200)
            ? 'rf-grid-cols-[repeat(3,31%)] rf-grid-rows-3'
            : 'rf-grid-cols-[repeat(2,45%)] rf-grid-rows-4'
        )
        break
      case 9:
        setClassname(
          mainHeight > mainWidth || (mainHeight < mainWidth && mainWidth > 1200)
            ? 'rf-grid-cols-[repeat(3,31%)] rf-grid-rows-3'
            : 'rf-grid-cols-[repeat(2,45%)] rf-grid-rows-5'
        )
        break
      default:
        break
    }
  }, [nbCards, mainWidth, mainHeight])

  return nbCards ? (
    <div
      className={`rf-w-full rf-flex rf-flex-col rf-gap-4 ${
        mode === Mode.vocal
          ? `vertical:rf-w-9/10 rf-pl-4 ${
              history
                ? 'rf-pr-0 large-vertical:rf-w-full'
                : 'rf-pr-40 large-vertical:rf-w-3/5'
            } vertical:rf-pl-0 vertical:rf-pr-0`
          : 'rf-px-4'
      } vertical:rf-items-center`}
    >
      {title && (
        <div
          className={`rf-w-full rf-text-left rf-title-large-size-auto ${
            !history && mode === Mode.vocal
              ? 'rf-text-truewhite rf-text-shadow-black'
              : 'rf-text-textModePanelConversationBot'
          }`}
        >
          {title}
        </div>
      )}
      <div
        ref={gridContainerRef}
        className={`rf-w-full rf-grid rf-justify-center rf-gap-x-[3.5%] ${classname}`}
        style={{
          maxHeight: history || mainHeight > mainWidth ? 'none' : maxHeight,
          rowGap: rowGap
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default Grid
