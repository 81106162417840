import type { RetorikActivity } from '../models/activityTypes'

/**
 * Check if the activity data need a display as list to be triggered
 * @param activity : RetorikActivity
 * @returns boolean
 */
const checkListDisplay = (activity?: RetorikActivity): boolean => {
  return !!(
    activity?.attachmentLayout?.toLowerCase() === 'davilist' ||
    (activity?.attachmentLayout !== 'carousel' &&
      activity?.attachmentLayout !== 'grid' &&
      activity?.attachments?.length &&
      activity.attachments.length > 1)
  )
}

export { checkListDisplay }
