import React from 'react'
import {
  setCurrentSubView,
  setDashboardOpened,
  useUtilsStore
} from '../../Contexts/utilsStore'
import { CurrentSubView, Depth } from '../../../models/enums'
import { FullScreen } from '../../Templates'
import Mobile from './Mobile'
import ParametersAndDashboardContainer from './ParametersAndDashboardContainer'

interface MobileContainerProps {
  hideMenu: boolean
}

const MobileContainer = ({ hideMenu }: MobileContainerProps): JSX.Element => {
  const dashboardOpened = useUtilsStore((state) => state.dashboardOpened)

  const toggleParameters = (): void => {
    setCurrentSubView(CurrentSubView.none)
    setDashboardOpened(!dashboardOpened)
  }

  return (
    <FullScreen
      className='large:rf-hidden rf-p-0 rf-m-0 rf-flex rf-flex-col-reverse rf-pointer-events-none vertical:rf-row-start-0 vertical:rf-row-end-13'
      depth={Depth.ui}
      background='transparent'
    >
      {/* Menu */}
      <Mobile
        parametersOpened={dashboardOpened}
        toggleParameters={toggleParameters}
      />

      {/* Parameters */}
      {dashboardOpened && (
        <ParametersAndDashboardContainer
          handleBack={toggleParameters}
          hideMenu={hideMenu}
        />
      )}
    </FullScreen>
  )
}

export default MobileContainer
