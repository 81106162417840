/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo } from 'react'
import { setLayout, useRetorikStore } from '../Contexts/retorikStore'
import { useViewStore, setDisplayControls } from '../Contexts/viewStore'
import { useUtilsStore } from '../Contexts/utilsStore'
import { openWidget } from '../Contexts/widgetStore'

import type { ChatbotData } from '../../models/types'
import { ContainerParent, Mode } from '../../models/enums'

import Background from '../Common/Background'
import UserRequest from '../AnswerPanel/UserRequest'
import ChatbotAndSubtitlesContainer from '../ChatbotAndSubtitles/ChatbotAndSubtitlesContainer'
import Logo from '../Common/Logo'
import BotResponse from '../AnswerPanel/BotResponse'
import RetorikLogo from '../Common/RetorikLogo'
import SubViews from './SubViews'
import ConversationPanel from '../AnswerPanel/ConversationPanel'
import MenuDisplayer from '../Menu/MenuDisplayer'
import ExtendIcon from '../Icons/Miscellaneous/ExtendIcon'

interface HomeViewProps {
  chatbotData: ChatbotData
  externalComponents?: Array<{ name: string; component: JSX.Element }>
}

const HomeView = ({
  chatbotData,
  externalComponents
}: HomeViewProps): JSX.Element => {
  const displayChangeLayoutButton = useRetorikStore(
    (state) => state.configuration.allowSwitchLayout
  )
  const currentLayout = useRetorikStore((state) => state.currentLayout)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const configuration = useRetorikStore((state) => state.configuration)
  const mode = useRetorikStore((state) => state.mode)
  const isMobile = useViewStore((state) => state.isMobile)
  const configurations = useViewStore((state) => state.configurations)
  const displayAgent = useUtilsStore((state) => state.displayAgent)

  const displayData = useMemo(() => {
    return configurations.views.home
  }, [configurations])

  useEffect(() => {
    setDisplayControls(!configuration.hideControls)
  }, [])

  const extendButtonHandler = () => {
    setLayout(ContainerParent.widget)
    openWidget()
  }

  return (
    <React.Fragment>
      {!!displayChangeLayoutButton &&
        currentLayout !== ContainerParent.widget && (
          <button
            onClick={extendButtonHandler}
            aria-label='Minimize'
            className='rf-absolute rf-z-exitwidget rf-flex rf-right-1 rf-top-1 rf-p-1 rf-bg-truewhite rf-border rf-border-trueblack rf-rounded-lg'
          >
            <ExtendIcon
              className='rf-h-4 rf-w-4'
              color='#000000'
              extended={true}
            />
          </button>
        )}
      {/* Background */}
      <Background config={displayData.background} />

      {/* Logo */}
      {configuration?.logo &&
        (!isMobile || (isMobile && mode !== Mode.text)) && <Logo />}

      {/* Chatbot + Subtitles */}
      {(!isMobile || (isMobile && mode !== Mode.text)) &&
        chatbotData &&
        displayAgent && (
          <ChatbotAndSubtitlesContainer
            className={`rf-relative rf-col-start-1 rf-col-end-9 ${
              chatbotData.fullWidthInDesktopMode
                ? 'large:rf-col-end-9'
                : 'large:rf-col-end-6'
            } large-vertical:rf-col-end-9 rf-self-end rf-h-full`}
            chatbotData={chatbotData}
            showSubtitles={true}
          />
        )}

      {/* Activity input / output */}
      {configuration?.answerpanel !== false && loaderClosed && (
        <React.Fragment>
          {!isMobile && <UserRequest />}
          {mode === Mode.vocal ? <BotResponse /> : <ConversationPanel />}
        </React.Fragment>
      )}

      {/* Menus */}
      <MenuDisplayer hideMenu={configuration?.hideMenu} />

      {/* Miscellaneous */}
      {loaderClosed && <SubViews externalComponents={externalComponents} />}

      {/* Retorik Logo on right bottom */}
      {!configuration?.hideRetorikLogo && <RetorikLogo />}
    </React.Fragment>
  )
}

export default HomeView
