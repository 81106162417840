import { webchatTokenRetrievalAddress } from '../models/constants'

const processBaseURI = (baseURI: string): string => {
  if (baseURI?.length) {
    if (baseURI.indexOf(`${webchatTokenRetrievalAddress}`) > -1) {
      console.warn(
        'Retorik Framework > "/K2.ContentFields.WebChat/api/GetWebchatToken" endpoint for directline token retrieval is deprecated and will be removed in the next versions of Retorik Studio'
      )
      baseURI = baseURI.replace(`${webchatTokenRetrievalAddress}`, '')
    }
  }

  return baseURI
}

export default processBaseURI
