import React, { useMemo, useState } from 'react'
import { useSpeechStore } from '../../Contexts/speechStore'

import type { RetorikActivity } from '../../../models/activityTypes'

import DisplayStreaming from './DisplayStreaming'
import DisplayNonStreaming from './DisplayNonStreaming'

interface DisplayStandardProps {
  triggerScroll: () => void
  botResponses: Array<RetorikActivity>
  isMobile?: boolean
  canBeStreaming: boolean
}

const DisplayStandard = ({
  botResponses,
  isMobile,
  triggerScroll,
  canBeStreaming
}: DisplayStandardProps): JSX.Element => {
  const currentStreaming = useSpeechStore((state) => state.currentStreaming)
  const streamingReplyToId = useSpeechStore((state) => state.streamingReplyToId)
  const [streamingEnded, setStreamingEnded] = useState<boolean>(false)

  const isStreaming = useMemo<boolean>(() => {
    return !!(canBeStreaming && streamingReplyToId)
  }, [canBeStreaming, streamingReplyToId])

  const streamingActivity = useMemo<RetorikActivity | undefined>(() => {
    if (botResponses.length && currentStreaming) {
      return botResponses.find(
        (act) =>
          act.replyToId === streamingReplyToId && act.value?.streamingCount
      )
    }

    return undefined
  }, [isStreaming, botResponses, currentStreaming])

  const nonStreamingActivities = useMemo<Array<RetorikActivity>>(() => {
    if (isStreaming) {
      return botResponses.filter(
        (act) =>
          act.replyToId === streamingReplyToId && !act.value?.streamingCount
      )
    }

    return botResponses
  }, [isStreaming, botResponses])

  return (
    <React.Fragment>
      {isStreaming ? (
        <React.Fragment>
          <DisplayStreaming
            triggerScroll={triggerScroll}
            setStreamingEnded={setStreamingEnded}
            streamingActivity={streamingActivity}
            currentStreaming={currentStreaming}
            isMobile={isMobile}
          />

          {streamingEnded && (
            <DisplayNonStreaming
              nonStreamingActivities={nonStreamingActivities}
            />
          )}
        </React.Fragment>
      ) : (
        <DisplayNonStreaming nonStreamingActivities={botResponses} />
      )}
    </React.Fragment>
  )
}

export default DisplayStandard
